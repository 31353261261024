import { H3, TextBox } from "helpers/Typography";
import { TechniquesCTA, Wrapper } from "../Shared/Shared";

const EFTCopy = () => {
  return (
    <Wrapper>
      <H3>
        Overview of the Emotional Freedom Technique (EFT), also known as Tapping{" "}
      </H3>
      <TextBox>
        Emotional Freedom Technique (EFT), also known as Tapping, is a powerful
        healing technique based on ancient Chinese healing principles. The EFT
        process can influence and release deep emotional and psychological
        issues. It can also be used as a helpful relaxation technique.
      </TextBox>
      <H3>How does it do this?</H3>
      <TextBox>
        By Tapping on the body’s established energy meridian points with the
        fingertips, mental and emotional blocks caused by traumas or difficult
        life events can be released. You will become aware of how you are
        feeling, Tapping into your subconscious and working through difficult
        memories and emotions.
      </TextBox>
      <TextBox>
        You might experience significant emotional release during the Tapping
        process. You can think of it as similar to acupuncture , but without the
        needles.
      </TextBox>
      <H3>What are the benefits of this?</H3>
      <TextBox>
        Positive health, wellbeing, personal development and spiritual growth
        requires a holistic approach. To be fully healthy and whole we need to
        focus on our Mind, Body and Spirit, including our subconscious. EFT
        addresses all of these areas.
      </TextBox>
      <TextBox>
        Emotional health is crucial to physical health and healing - and this is
        why EFT is so powerful in supporting this. EFT will remove the emotional
        barriers that could be impacting your wellbeing or hindering you from
        moving forward in your life.
      </TextBox>
      <H3>How does EFT support the coaching sessions?</H3>
      <TextBox>
        EFT is optional within the coaching package, However, EFT is a beautiful
        accompaniment to coaching in order to support transformation, releasing
        difficult emotions and letting go.
      </TextBox>
      <TextBox>
        It is only when we let go, that we can make space for something new.
      </TextBox>
      <TextBox>
        Letting go of painful memories and emotions, will clear your energy
        field and open you up to endless possibilities.
      </TextBox>
      <TextBox>I believe healing is a continuous part of our existence.</TextBox>
      <TextBox>
        During the Tapping session I will be there to support you in the healing
        space, and be present for you to access a difficult memory you want to
        work on.
      </TextBox>
      <TextBox>
        Going deep into the subconscious takes courage but the benefits can be
        life changing.
      </TextBox>
      <TechniquesCTA />
    </Wrapper>
  );
};

export default EFTCopy;
