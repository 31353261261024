import { NavBarLink, NavLinksWithSub, NAV_LINKS } from "constants/navlinks";
import { Link, useLocation } from "react-router-dom";

interface Props {
  linkColor: string;
  onClickHandler?: () => void;
  showUnderline?: boolean;
}

const MobileNavLinks = ({
  linkColor,
  onClickHandler,
  showUnderline,
}: Props) => {
  const location = useLocation();
  const home: NavLinksWithSub[] = [
    {
      text: "Home",
      path: "/",
      show: true,
    },
  ];
  const contact: NavLinksWithSub[] = [
    {
      text: "Contact",
      path: "contact",
      show: true,
    },
  ];
  const ALL_NAV_LINKS = [...home, ...NAV_LINKS, ...contact];

  return (
    <>
      {ALL_NAV_LINKS.map((link) => {
        if (link.show) {
          return (
            <li key={link.path}>
              <NavBarLink
                onClick={onClickHandler}
                linkColor={linkColor}
                mobileView
                currentPage={
                  showUnderline &&
                  (link.path === location.pathname.slice(1) ||
                    link.path === location.pathname)
                }
              >
                <Link to={link.path}>{link.text}</Link>
              </NavBarLink>
              {link.sub && (
                <ul>
                  {link.sub.map((s) => {
                    return (
                      <li key={s.path}>
                        <NavBarLink
                          onClick={onClickHandler}
                          linkColor={linkColor}
                          mobileView
                          currentPage={
                            showUnderline && s.path === location.pathname
                          }
                        >
                          <Link to={s.path}>{s.text}</Link>
                        </NavBarLink>
                      </li>
                    );
                  })}
                </ul>
              )}
            </li>
          );
        } else return false;
      })}
    </>
  );
};

export default MobileNavLinks;
