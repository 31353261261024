import HeroImageShort from "components/Header/HeroImageShort";
import Header from "images/eft-header.jpg";
import EFTCopy from "./EFTCopy";
import PersonalExperience from "./PersonalExperience";

const EFT = () => {
  return (
    <>
      <HeroImageShort
        imgDesktop={{ img: Header, position: "center" }}
        imgWide={{ img: Header, position: "center" }}
        imgMobile={{ img: Header, position: "center" }}
      >
        Emotional Freedom Technique
      </HeroImageShort>
      <EFTCopy />
      <PersonalExperience />
    </>
  );
};

export default EFT;
