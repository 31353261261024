import styled from "styled-components";
import { BREAKPOINTS, COLORS } from "constants/style";
import { CursiveH1 } from "helpers/Typography";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 5vh);
`;

const GreenBG = styled.div`
  position: absolute;
  margin-top: -20px;
  right: 0;
  width: 75%;
  background-color: ${COLORS.green};
  height: 100%;

  @media (max-width: ${BREAKPOINTS.mobileMax}) {
    width: 100%;
    margin-top: 0;
  }
`;

const GreenLine = styled.div`
  width: 100%;
  background-color: ${COLORS.green};
  height: 2px;
  margin-top: 20px;

  @media (max-width: ${BREAKPOINTS.mobileMax}) {
    display: none;
  }
`;

const GreenLineBottom = styled.div`
  width: 100%;
  background-color: ${COLORS.green};
  height: 2px;
  margin-top: 20px;

  @media (max-width: ${BREAKPOINTS.mobileMax}) {
    display: none;
  }
`;

const ContentWrapper = styled.div`
  max-width: 1512px;
  margin: 0 auto;
`;

const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media (max-width: ${BREAKPOINTS.mobileMax}) {
    flex-direction: column;
  }
`;

const HeaderTextContainer = styled.div`
  display: flex;
  position: relative;
  width: 50%;
  align-items: center;

  @media (max-width: ${BREAKPOINTS.mobileMax}) {
    width: 100%;
  }
`;

const HeaderTextBG = styled.div`
  margin-left: -75px;
  width: 100%;
  max-width: 650px;
  background-color: rgba(255, 255, 255, 0.7);
  text-align: left;
  padding: 70px 50px 70px 70px;

  @media (max-width: ${BREAKPOINTS.tabletMax}) {
    padding: 50px;
  }

  @media (max-width: ${BREAKPOINTS.mobileMax}) {
    margin-left: 0;
    padding: 35px;
    background-color: transparent;
  }
`;

const HeaderText = styled.div`
  font-family: "EB Garamond";
  font-size: 1.75rem;
  line-height: 2.75rem;

  @media (max-width: ${BREAKPOINTS.tabletMax}) {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  width: 48%;
  height: auto;
  padding: 0 40px 0 12%;
  margin-top: 20px;

  @media (max-width: ${BREAKPOINTS.mobileMax}) {
    flex-direction: column;
    width: 100%;
    padding: 0;
    margin-top: 0;
  }
`;

const HeaderImage = styled.img`
  position: relative;
  width: 100%;
  margin-bottom: -50px;

  @media (max-width: ${BREAKPOINTS.mobileMax}) {
    margin-bottom: 0;
  }
`;

type Props = {
  image: string;
  imageAlt: string;
  header: string;
  subText: string;
};

const SmallImageHeader = ({ image, imageAlt, header, subText }: Props) => {
  return (
    <Wrapper>
      <GreenLine />
      <ContentWrapper>
        <GreenBG />
        <HeaderContent>
          <ImageContainer>
            <HeaderImage src={image} alt={imageAlt} />
          </ImageContainer>
          <HeaderTextContainer>
            <HeaderTextBG>
              <CursiveH1>{header}</CursiveH1>
              <HeaderText>{subText}</HeaderText>
            </HeaderTextBG>
          </HeaderTextContainer>
        </HeaderContent>
      </ContentWrapper>
      <GreenLineBottom />
    </Wrapper>
  );
};

export default SmallImageHeader;
