import Button from "components/shared/Button";
import { BREAKPOINTS } from "constants/style";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 80%;
  max-width: 800px;
  margin: 100px auto;
  text-align: left;

  @media (max-width: ${BREAKPOINTS.mobileMax}) {
    margin: 70px auto 100px;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  margin: 80px auto 0;
  text-align: center;
`;

export const TechniquesCTA = () => (
  <ButtonWrapper>
    <Link to="/contact">
      <Button text="Get started on your journey" />
    </Link>
  </ButtonWrapper>
);
