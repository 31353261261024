import Button from "components/shared/Button";
import SplitSection, {
  SplitSectionPaddingWrapper,
} from "components/shared/SplitSection";
import { BREAKPOINTS } from "constants/style";
import { CursiveH2, H3, TextBox } from "helpers/Typography";
import AboutMe from "images/sue-bio-intro.jpg";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  @media (max-width: ${BREAKPOINTS.twoColumnBreakpoint}) {
    align-items: center;
    width: 100%;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;

  @media (max-width: ${BREAKPOINTS.twoColumnBreakpoint}) {
    width: 100%;
    height: 500px;
  }

  @media (max-width: ${BREAKPOINTS.mobileMax}) {
    height: 100%;
  }
`;

const Image = styled.img`
  height: 100%;
  position: absolute;
  box-shadow: 30px 30px 0px 0px #e9edc9;

  @media (max-width: ${BREAKPOINTS.mobileMax}) {
    width: 80%;
    height: auto;
    position: relative;
    box-shadow: 25px 25px 0px 0px #e9edc9;
  }

  @media (max-width: ${BREAKPOINTS.mobileSmallMax}) {
    width: 90%;
    box-shadow: 20px 20px 0px 0px #e9edc9;
  }
`;

const StoryText = () => {
  return (
    <TextContainer>
      <CursiveH2>Hello, I'm Sue!</CursiveH2>
      <Container>
        <TextBox>
          I have a strong desire to help people transform and grow. Providing
          deep coaching sessions, I coach from both my head and my heart.
        </TextBox>
        <H3>What does this mean?</H3>
        <TextBox>
          We can allow for a more heartfelt state of being. It allows for a more
          authentic space; somewhere you are safe to be vulnerable, a space
          where you can be more at ease to reveal your inner world with the
          freedom to reveal your shadow side, fears or emotions.
        </TextBox>
        <H3>What’s the benefit of this?</H3>
        <TextBox>Long lasting change, growth and personal development.</TextBox>
      </Container>
      <Link to="my-story">
        <Button text="Get to know me" />
      </Link>
    </TextContainer>
  );
};

const StorySection = () => {
  return (
    <SplitSectionPaddingWrapper>
      <SplitSection
        split="half"
        left={
          <ImageContainer>
            <Image src={AboutMe} alt="About me" />
          </ImageContainer>
        }
        right={<StoryText />}
      />
    </SplitSectionPaddingWrapper>
  );
};

export default StorySection;
