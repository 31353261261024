import { NavBarLink, NAV_LINKS } from "constants/navlinks";
import { Link } from "react-router-dom";

interface Props {
  linkColor: string;
  showUnderline?: boolean;
}

const NavLinks = ({ linkColor, showUnderline }: Props) => {

  return (
    <>
      {NAV_LINKS.map((link) => {
        return (
          <li key={link.path}>
            <NavBarLink linkColor={linkColor}>
              <Link to={link.path}>{link.text}</Link>
            </NavBarLink>
          </li>
        );
      })}
    </>
  );
};

export default NavLinks;
