import SplitSection from "components/shared/SplitSection";
import styled from "styled-components";
import AboutMeTwo from "images/my-story-2.jpg";
import AboutMeThree from "images/my-story-3.jpg";
import { H3, TextBox } from "helpers/Typography";
import { BREAKPOINTS } from "constants/style";

const Wrapper = styled.div`
  max-width: 1200px;
  padding: 0 50px;
  margin: 100px auto;

  @media (max-width: ${BREAKPOINTS.mobileSmallMax}) {
    padding: 0 20px;
  }
`;

const StandOutText = styled.div`
  width: 100%;
  margin: 50px 0 70px;
  text-align: center;
  font-family: "Photograph Signature";
  font-size: 60px;
  line-height: 60px;
`;

const Image = styled.img`
  width: 100%;
`;

const StoryTextWrapper = styled.div`
  text-align: left;
`;

const LastSectionWrapper = styled.div`
  max-width: 900px;
  margin: -20px auto 0;

  p {
    text-align: center;
  }
`;

const StoryTextOne = () => {
  return (
    <StoryTextWrapper>
      <TextBox>
        When my mother and I had to look for a safe place to live, I was only
        three years old. For a period, we were homeless and without a place to
        go.
      </TextBox>
      <H3>
        From a young age, I developed the false belief that my role was to take
        care of others.
      </H3>
      <TextBox>
        By my teenage years I had developed an unhealthy disturbance in eating
        behavior, and I became ill.
      </TextBox>
      <TextBox>
        I recovered and focused on my studies and career, but being a classic
        overachiever, over giver, people pleaser and truly ambitious, I
        experienced severe burnout on more than one occasion.
      </TextBox>
      <H3>Always pushing myself to the limit.</H3>
      <TextBox>Then life changed in a way that forced me to STOP.</TextBox>
      <TextBox>
        I left a highly stressful job. And a long term relationship concluded.
        It was a point in my life, where I decided it was time to look INWARD.
      </TextBox>
    </StoryTextWrapper>
  );
};

const StoryTextTwo = () => {
  return (
    <StoryTextWrapper>
      <H3>Through introspection, I started to find my inner light.</H3>
      <TextBox>
        It was time to show up for myself, instead of waiting for others to show
        up for me. What followed was a journey of pure self discovery, personal
        growth, and deep transformation.
      </TextBox>
      <TextBox>
        I took a solo trip, where I found peace and joy through nature,
        meditation and yoga. Spending time alone gave me the chance to discover
        my <strong>true self</strong>. I started to feel more aligned with the
        universe.
      </TextBox>
      <TextBox>
        On my return to London, I started working with a life coach.I enrolled
        to study Transformational Life Coaching. I had found my true purpose in
        life. Life would never be the same again, because I had found my
        calling.
      </TextBox>
      <TextBox>
        I secured my desired job in a leading mental health charity. I learned
        how to shift my mindset to one of positivity and gratitude.
      </TextBox>
      <TextBox>
        I started to prioritize self care and cultivate self love.
      </TextBox>
    </StoryTextWrapper>
  );
};

const ImgContainer = styled.div`
  height: 100%;
  display: grid;
  align-items: center;
`;

const StoryCopy = () => {
  return (
    <Wrapper>
      <StandOutText>Trauma creates wounds that take time to heal</StandOutText>
      <SplitSection
        split="twoThirds"
        right={
          <ImgContainer>
            <Image src={AboutMeTwo} alt="About me" />
          </ImgContainer>
        }
        left={<StoryTextOne />}
      />
      <StandOutText>
        It was then my journey towards self love began
      </StandOutText>
      <SplitSection
        reverse
        split="twoThirds"
        right={
          <ImgContainer>
            <Image src={AboutMeThree} alt="About me" />
          </ImgContainer>
        }
        left={<StoryTextTwo />}
      />
      <StandOutText>I discovered that my needs were important too</StandOutText>
      <LastSectionWrapper>
        <TextBox>
          With the help of a coach, and through spiritual and mindfulness
          practices, I became aligned with my life purpose - serving others
          through Transformative Coaching.
        </TextBox>
        <TextBox>
          It was through all of this I realized that what truly brought me peace
          and joy, was already WITHIN me. Through pain comes POWER, and through
          self love and compassion we can find peace and fulfillment.
        </TextBox>
      </LastSectionWrapper>
    </Wrapper>
  );
};

export default StoryCopy;
