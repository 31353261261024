import { COLORS } from "constants/style";
import React from "react";
import styled from "styled-components";

type ButtonProps = {
  text: string;
  disabled?: boolean;
  onClick?: (e?: React.SyntheticEvent<HTMLElement>) => void;
};

const ButtonStyled = styled.button`
  background-color: transparent;
  color: ${COLORS.black};
  border: 1px solid ${COLORS.black};
  font-size: 1rem;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding: 1rem 2.5rem;
  cursor: pointer;
  width: fit-content;

  &:hover {
    background: ${COLORS.brown};
    color: ${COLORS.white};
  }

  &:disabled {
    color: grey;
    opacity: 0.7;
    cursor: default;
  }
`;

const Button = ({ text, disabled, onClick }: ButtonProps) => {
  return (
    <ButtonStyled disabled={disabled} onClick={onClick}>
      {text}
    </ButtonStyled>
  );
};

export default Button;
