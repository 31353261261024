import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";
import { COLORS } from "constants/style";
import styled from "styled-components";
import { ErrorText, SuccessfulText } from "helpers/Typography";
import { ReactComponent as LoadingIndicator } from "images/icons/loading-indicator.svg";

const Wrapper = styled.div`
  max-width: 600px;
  margin: 0 auto;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  * {
    font-family: Montserrat;
    color: ${COLORS.darkGrey};
  }

  input,
  textarea {
    padding: 1rem 1rem;
  }

  input,
  textarea,
  button {
    width: 100%;
    max-width: 100%;
    border: 1px solid ${COLORS.black};
    margin: 0.5rem 0;

    svg {
      height: 25px;
      width: 25px;
    }
  }
  button[type="submit"] {
    font-size: 1.25rem;
    text-transform: uppercase;
    background: ${COLORS.brown};
    color: ${COLORS.white};
    width: auto;
    height: 45px;
  }
  input::placeholder,
  textarea::placeholder {
    color: #7a7a7a;
    text-transform: uppercase;
  }
  input.error,
  textarea.error {
    background: #ad4747;
    color: #fff;
  }
  input.error::placeholder,
  textarea.error::placeholder {
    color: black;
  }

  textarea {
    height: 10rem;
  }
`;

const Form = () => {
  const form = useRef<HTMLFormElement>(null);
  const captchaRef = useRef<ReCAPTCHA>(null);
  const [toSend, setToSend] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [error, setError] = useState<string | undefined>();
  const [sendSuccessful, setSendSuccessful] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(undefined);
    setSendSuccessful(false);

    if (form.current && captchaRef.current) {
      setLoading(true);
      const token = captchaRef.current.getValue();
      const params = { ...toSend, "g-recaptcha-response": token };
      emailjs
        .send(
          process.env.REACT_APP_EMAILJS_SERVICE_ID || "",
          process.env.REACT_APP_EMAILJS_TEMPLATE_ID || "",
          params,
          process.env.REACT_APP_EMAILJS_PUBLIC_KEY || ""
        )
        .then(
          (result) => {
            setLoading(false);
            setSendSuccessful(true);
          },
          (error) => {
            setLoading(false);
            if (
              error.text.includes(
                "The g-recaptcha-response parameter not found"
              )
            ) {
              setError("Please check the reCAPTCHA checkbox");
            } else {
              setError(
                "There was an error sending your message. Please try again."
              );
            }
          }
        );
    }
  };

  return (
    <Wrapper>
      <StyledForm ref={form} onSubmit={sendEmail}>
        <input
          type="text"
          placeholder="Full name"
          name="name"
          onChange={(e) => setToSend({ ...toSend, name: e.target.value })}
          required
          disabled={sendSuccessful}
        />

        <input
          type="email"
          placeholder="Email"
          name="email"
          onChange={(e) => setToSend({ ...toSend, email: e.target.value })}
          required
          disabled={sendSuccessful}
        />

        <textarea
          placeholder="Message"
          name="message"
          onChange={(e) => setToSend({ ...toSend, message: e.target.value })}
          required
          disabled={sendSuccessful}
        ></textarea>

        <button type="submit" disabled={sendSuccessful}>
          {loading ? <LoadingIndicator /> : "Send"}
        </button>
        {!sendSuccessful && (
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ""}
            ref={captchaRef}
          />
        )}
      </StyledForm>
      {error && <ErrorText>{error}</ErrorText>}
      {sendSuccessful && (
        <SuccessfulText>
          Message sent! I will reply as soon as I can.
        </SuccessfulText>
      )}
    </Wrapper>
  );
};

export default Form;
