import React from 'react'
import styled from "styled-components";
import LogoImg from "images/sue-logo.png";
import { Link } from 'react-router-dom';
import { BREAKPOINTS } from 'constants/style';

const LogoContainer = styled.img`
  width: 230px;

  @media (max-width: ${BREAKPOINTS.mobileMax}) {
    width: 210px;
  }

  @media (max-width: ${BREAKPOINTS.mobileSmallMax}) {
    width: 185px;
  }
`

const Logo = () => {
  return (
    <Link to="/"><LogoContainer src={LogoImg} alt="Sue Boland" /></Link>
  )
}

export default Logo