import { Link } from "react-router-dom";
import styled from "styled-components";
import { BREAKPOINTS, COLORS } from "constants/style";
import HorizontalRule from "./HorizontalRule";
import FooterNavLinks from "../shared/FooterNavLinks";
import Insta1 from "images/instagram/insta-1.jpg";
import Insta2 from "images/instagram/insta-2.jpg";
import Insta3 from "images/instagram/insta-3.jpg";
import Insta4 from "images/instagram/insta-4.jpg";
import Insta5 from "images/instagram/insta-5.jpg";
import Insta6 from "images/instagram/insta-6.jpg";
import AnimasLogo from "images/animas-logo.png";
import AcademyEFTLogo from "images/academyEFT-logo.png";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 60px 0 40px 0;
  width: 100%;
  background-color: ${COLORS.brown};
  justify-content: center;
  align-items: center;
  column-gap: 70px;
  text-align: left;

  @media (max-width: ${BREAKPOINTS.tabletMax}) {
    column-gap: 40px;
  }

  @media (max-width: ${BREAKPOINTS.mobileMax}) {
    flex-direction: column;
  }
`;

const AttributionBar = styled.div`
  width: 100%;
  padding: 20px 10px;
  display: flex;
  justify-content: center;
  background-color: ${COLORS.brown};
  font-size: 0.75rem;
  line-height: 0.75rem;
  text-transform: uppercase;
  a {
    text-decoration: underline;
  }
`;

const VerticalRule = styled.div`
  border-left: 1px solid #000;
  height: 150px;

  @media (max-width: ${BREAKPOINTS.mobileMax}) {
    display: none;
  }
`;

const HorizRuleWrapper = styled.div`
  display: none;

  @media (max-width: ${BREAKPOINTS.mobileMax}) {
    width: 80%;
    max-width: 250px;
    margin: 25px 0;
    display: inline-block;
  }
`;

const LinksWrapper = styled.ul`
  list-style-type: none;
  text-decoration: none;
  margin: 0;
  padding: 0;

  li {
    text-transform: uppercase;
    margin: 15px 1rem;
    font-size: 1rem;
    line-height: 20px;
    font-weight: 500;
    letter-spacing: 1px;
  }
`;

const InstagramFeed = styled.div`
  position: relative;
  width: 100%;
  display: flex;

  img {
    width: calc(100% / 6);
  }

  @media (max-width: ${BREAKPOINTS.mobileMax}) {
    flex-wrap: wrap;
    img {
      width: calc(100% / 3);
    }
  }
`;

const FollowMeWrapper = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  bottom: -30px;
`;

const FollowMe = styled.div`
  background-color: rgba(255, 255, 255, 0.75);
  font-family: "Montserrat";
  font-size: 1.15rem;
  line-height: 1.4rem;
  text-transform: uppercase;
  padding: 20px 40px;

  @media (max-width: ${BREAKPOINTS.mobileSmallMax}) {
    max-width: 80%;
    margin: 0 auto;
  }
`;

const CredentialsLogo = styled.img`
  width: 180px;

  @media (max-width: ${BREAKPOINTS.mobileMax}) {
    width: 210px;
  }

  @media (max-width: ${BREAKPOINTS.mobileSmallMax}) {
    width: 185px;
  }
`;

const LogosWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${BREAKPOINTS.twoColumnBreakpoint}) {
    flex-direction: column;
  }
`;

const Footer = () => {
  return (
    <>
      <InstagramFeed>
        <img src={Insta1} alt="Instagram post" />
        <img src={Insta2} alt="Instagram post" />
        <img src={Insta3} alt="Instagram post" />
        <img src={Insta4} alt="Instagram post" />
        <img src={Insta5} alt="Instagram post" />
        <img src={Insta6} alt="Instagram post" />
        <FollowMeWrapper>
          <a
            href="https://www.instagram.com/suebolandtransformativecoach/"
            target="_blank"
            rel="noreferrer"
          >
            <FollowMe>Follow me on Instagram</FollowMe>
          </a>
        </FollowMeWrapper>
      </InstagramFeed>
      <Container>
        <LinksWrapper>
          <FooterNavLinks linkColor={COLORS.black} />
        </LinksWrapper>
        <HorizRuleWrapper>
          <HorizontalRule />
        </HorizRuleWrapper>
        <VerticalRule />
        <LogosWrapper>
          <CredentialsLogo src={AnimasLogo} alt="Animas Qualified Coach" />
          <CredentialsLogo
            src={AcademyEFTLogo}
            alt="Academy EFT Practitioner"
          />
        </LogosWrapper>
        <HorizRuleWrapper>
          <HorizontalRule />
        </HorizRuleWrapper>
        <VerticalRule />
        <LinksWrapper>
          <li>
            <Link to="contact">Contact</Link>
          </li>
          <li>
            <Link to="privacy-policy">Privacy Policy</Link>
          </li>
        </LinksWrapper>
      </Container>
      <AttributionBar>
        <div>
          Photography by{" "}
          <a
            href="https://www.shotbymartyna.com/"
            rel="nofollow noreferrer"
            target="_blank"
          >
            Martyna
          </a>
          {" "}| Website by Brittany LaGambina
        </div>
      </AttributionBar>
    </>
  );
};

export default Footer;
