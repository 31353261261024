import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Story from "pages/MyStory/Story";
import Testimonials from "pages/Testimonials/Testimonials";
import Home from "pages/Home/Home";
import Services from "pages/Services/Services";
import WorkWithMe from "pages/WorkWithMe/WorkWithMe";
import ScrollToTop from "hooks/ScrollToTop";
import EFT from "pages/Services/Techniques/EFT/EFT";
import PositivePsychology from "pages/Services/Techniques/PositivePsychology/PositivePsychology";
import Mindfulness from "pages/Services/Techniques/Mindfulness/Mindfulness";
import TransformativeCoaching from "pages/Services/Techniques/TransformativeCoaching/TransformativeCoaching";
import Contact from "pages/Contact/Contact";
import PrivacyPolicy from "pages/PrivacyPolicy/PrivacyPolicy";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <ScrollToTop />
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<Home />} />
          <Route path="my-story" element={<Story />} />
          <Route path="testimonials" element={<Testimonials />} />
          <Route path="clients" element={<WorkWithMe />} />
          <Route path="contact" element={<Contact />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="services" element={<Services />} />
          <Route path="services/eft" element={<EFT />} />
          <Route path="services/positive-psychology" element={<PositivePsychology />} />
          <Route path="services/mindfulness" element={<Mindfulness />} />
          <Route path="services/transformative-coaching" element={<TransformativeCoaching />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
