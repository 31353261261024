import styled from "styled-components";
import SplitSection from "components/shared/SplitSection";
import { BREAKPOINTS, COLORS } from "constants/style";
import MoreAboutMeImg from "images/more-me-2.jpg";
import { CursiveH2, TextBox } from "helpers/Typography";

const Wrapper = styled.div`
  margin: 0 auto 50px;
  width: 80%;
  max-width: 1200px;
  background-color: ${COLORS.lightGreen};
  padding: 30px 50px;

  @media (max-width: ${BREAKPOINTS.mobileMax}) {
    width: 90%;
    padding: 30px;
  }

  @media (max-width: ${BREAKPOINTS.mobileSmallMax}) {
    width: 90%;
    padding: 30px 20px;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const Image = styled.img`
  width: 100%;
  max-width: 350px;
  border: 10px solid ${COLORS.white};
  transform: rotate(-3.5deg);
  margin: 20px auto;
`;

const CopyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  height: 100%;
  max-width: 500px;
  margin: 0 auto;

  @media (max-width: ${BREAKPOINTS.twoColumnBreakpoint}) {
    max-width: 100%;
  }
`;

const Copy = () => {
  return (
    <CopyWrapper>
      <CursiveH2>A bit more about me...</CursiveH2>
      <TextBox>
        I trained in Transformational Life Coaching with the Animas Center for
        coaching, which is the world's leading school of transformative
        coaching, accredited by the globally recognised International Coach
        federation (ICF).
      </TextBox>
      <TextBox>
        I also trained in Mindfulness Coaching and Positive Psychology in
        coaching, with the Animas Center.
      </TextBox>
      <TextBox>
        I have an honors degree in Social Care and have worked in a variety of
        Health and Social Care settings in both Ireland and the UK . I have
        worked in the industry for 17 years.
      </TextBox>
      <TextBox>
        I’m currently the Head of Mental Health Services for a
        leading mental health charity in the UK.
      </TextBox>
    </CopyWrapper>
  );
};

const ImageSide = () => {
  return (
    <ImageWrapper>
      <Image src={MoreAboutMeImg} alt="About me" />
    </ImageWrapper>
  );
};

const MoreAboutMe = () => {
  return (
    <Wrapper>
      <SplitSection
        left={<ImageSide />}
        right={<Copy />}
        split={"half"}
        bgColor={COLORS.lightGreen}
      />
    </Wrapper>
  );
};

export default MoreAboutMe;
