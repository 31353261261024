import Button from "components/shared/Button";
import SplitSection, {
  SplitSectionPaddingWrapper,
} from "components/shared/SplitSection";
import { BREAKPOINTS } from "constants/style";
import { H2, TextBox, UnderlineStyle } from "helpers/Typography";
import WhoICoachImg from "images/who-i-work-with.jpg";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  @media (max-width: ${BREAKPOINTS.twoColumnBreakpoint}) {
    align-items: center;
    width: 100%;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;

  @media (max-width: ${BREAKPOINTS.twoColumnBreakpoint}) {
    width: 100%;
    height: 500px;
  }

  @media (max-width: ${BREAKPOINTS.mobileMax}) {
    height: 100%;
  }
`;

const Image = styled.img`
  width: 90%;
  position: absolute;
  box-shadow: 30px 30px 0px 0px #e9edc9;

  @media (max-width: ${BREAKPOINTS.mobileMax}) {
    width: 80%;
    height: auto;
    position: relative;
    box-shadow: 25px 25px 0px 0px #e9edc9;
  }

  @media (max-width: ${BREAKPOINTS.mobileSmallMax}) {
    width: 90%;
    box-shadow: 20px 20px 0px 0px #e9edc9;
  }
`;

const H2PaddingWrapper = styled.div`
  padding: 0 0 20px;
  width: fit-content;
`;

const StoryText = () => {
  return (
    <TextContainer>
      <H2PaddingWrapper>
        <H2>Who do I coach?</H2>
        <UnderlineStyle />
      </H2PaddingWrapper>
      <Container>
        <TextBox>
          Any one that is willing to commit to the process and put their energy
          into their transformation. Any one that is on a mission to make a
          change in their life and ready to dedicate the time to do so.
        </TextBox>
        <TextBox>
          Any one that might want to try something new for their own self
          development and progression. Anyone that is ready to heal
        </TextBox>
      </Container>
      <Link to="/clients">
        <Button text="Working with me" />
      </Link>
    </TextContainer>
  );
};

const WhoICoach = () => {
  const isTablet = useMediaQuery({
    query: `(max-width: ${BREAKPOINTS.tabletMax})`,
  });
  return (
    <SplitSectionPaddingWrapper>
      {isTablet ? (
        <SplitSection
          split="half"
          left={
            <ImageContainer>
              <Image src={WhoICoachImg} alt="Who I coach" />
            </ImageContainer>
          }
          right={<StoryText />}
        />
      ) : (
        <SplitSection
          split="half"
          right={
            <ImageContainer>
              <Image src={WhoICoachImg} alt="Who I coach" />
            </ImageContainer>
          }
          left={<StoryText />}
        />
      )}
    </SplitSectionPaddingWrapper>
  );
};

export default WhoICoach;
