import { H3, TextBox } from "helpers/Typography";
import { TechniquesCTA, Wrapper } from "../Shared/Shared";

const Copy = () => {
  return (
    <Wrapper>
      <H3>What is Transformative Coaching?</H3>
      <TextBox>
        Transformation is a thorough change in a person's form or character.
        Most of us change all the time, but we don't necessarily transform
      </TextBox>
      <TextBox>
        Transformative coaching focuses on self actualisation, which is the
        realisation and fulfillment of one's talents and full potential.
        Becoming and doing everything you're capable of.
      </TextBox>
      <TextBox>
        Transformative coaching dives deep into the psyche and really focuses on
        who you are at the core of your being, and who you would like to become.
      </TextBox>
      <H3>How can Transformative Coaching help you?</H3>
      <TextBox>
        As a transformational coach I will support you to dive beneath the
        surface and immerse yourself in self exploration, looking at your
        values, limiting beliefs, perceptions about who you are and your purpose
        in the world. Examining these areas will help you understand why you are
        experiencing life the way you do and what needs to change going forward
        to get you from where you are, to where you want to be.
      </TextBox>
      <TextBox>
        A transformative approach will support you in facing your shadow side,
        your fears and limiting beliefs in order to set yourself free from these
        thought patterns, that have been holding you back for too long. Change
        will then occur in your psych , creating growth and development leading
        to shifts in behaviors, thoughts, choices and emotions.
      </TextBox>
      <TextBox>
        As a result, and over time real transformation occurs, as new and
        necessary thoughts, feelings, behaviors and emotions have developed
        naturally.
      </TextBox>
      <TextBox>This leads to the change you want to see. Magic.</TextBox>
      <TechniquesCTA />
    </Wrapper>
  );
};

export default Copy;
