import styled from "styled-components";
import { BREAKPOINTS, COLORS } from "constants/style";
import { parseMarkdown } from "helpers/markdown";
import { Testimonial } from "helpers/testimonials";
import { Author } from "helpers/Typography";
import QuotationMark from "images/icons/quotation-marks.svg";

const TestimonialContainer = styled.div<{ bgColor: string }>`
  margin: 0 auto;
  padding: 100px 20%;
  width: 100%;
  background-color: ${(props) => props.bgColor};

  @media (max-width: ${BREAKPOINTS.mobileMax}) {
    padding: 100px 10%;
  }
`;

export const TestimonialCopy = styled.div`
  position: relative;
  z-index: 1;
  font-size: 1.4rem;
  line-height: 2rem;
  font-family: "EB Garamond";
  text-align: left;
  margin: 0 0 2rem;

  .pseudo-wrapper {
    position: absolute;
  }

  ::after {
    position: absolute;
    z-index: -1;
    content: "";
    -webkit-mask: url(${QuotationMark}) no-repeat 50% 50%;
    mask: url(${QuotationMark}) no-repeat 50% 50%;
    -webkit-mask-size: cover;
    mask-size: cover;
    background-color: ${COLORS.lightGrey};
    display: inline-block;
    width: 70px;
    height: 55px;
    left: -45px;
    top: -25px;
  }

  @media (max-width: ${BREAKPOINTS.mobileMax}) {
    text-align: left;

    ::after {
      left: -20px;
    }
  }
`;

type Props = Omit<Testimonial, "quote"> & {
  index: number;
};

const TestimonialRow = ({ name, full, index }: Props) => {
  const color =
    index % 2 === 0 || index === 0 ? COLORS.white : COLORS.lightGreen;

  return (
    <TestimonialContainer bgColor={color}>
      <TestimonialCopy>
        <div id="pseudo-wrapper">{parseMarkdown(full)}</div>
      </TestimonialCopy>
      <Author>- {name}</Author>
    </TestimonialContainer>
  );
};

export default TestimonialRow;
