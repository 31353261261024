import { ReactNode } from "react";
import styled from "styled-components";
import { BREAKPOINTS } from "constants/style";
import { useMediaQuery } from "react-responsive";

export const Hero = styled.div<{ image: string; position: string }>`
  position: relative;
  background-image: url(${(props) => props.image});
  width: 100%;
  height: 80vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: ${(props) => props.position};
`;

type ImageProps = {
  img: string;
  position: string;
};

type Props = {
  imgDesktop: ImageProps;
  imgWide: ImageProps;
  imgMobile: ImageProps;
  children: ReactNode;
};

const HeroImage = ({ imgDesktop, imgMobile, imgWide, children }: Props) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${BREAKPOINTS.mobileMax})`,
  });
  const isTablet = useMediaQuery({
    query: `(max-width: ${BREAKPOINTS.tabletMax})`,
  });
  const isLandscape = useMediaQuery({ query: "(orientation: landscape)" });

  return (
    <>
      {isMobile ? (
        <Hero image={imgMobile.img} position={imgMobile.position}>
          {children}
        </Hero>
      ) : isTablet || (isMobile && isLandscape) ? (
        <Hero image={imgDesktop.img} position={imgDesktop.position}>
          {children}
        </Hero>
      ) : (
        <Hero image={imgWide.img} position={imgWide.position}>
          {children}
        </Hero>
      )}
    </>
  );
};

export default HeroImage;
