import HeroImageShort from "components/Header/HeroImageShort";
import Header from "images/positive-psychology-header.jpg";
import Copy from "./Copy";

const PositivePsychology = () => {
  return (
    <>
      <HeroImageShort
        imgDesktop={{ img: Header, position: "center" }}
        imgWide={{ img: Header, position: "center" }}
        imgMobile={{ img: Header, position: "center" }}
      >
        Positive Psychology in Coaching
      </HeroImageShort>
      <Copy />
    </>
  );
};

export default PositivePsychology;
