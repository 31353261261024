import { BREAKPOINTS } from "constants/style";
import { Bold, TextBox } from "helpers/Typography";
import styled from "styled-components";

const Wrapper = styled.div`
  margin: 50px auto 100px;
`;

export const ContactH1 = styled.h1`
  font-family: "Amalfi Coast";
  font-size: 3.25rem;
  line-height: 7.25rem;
  margin: 0;

  @media (max-width: ${BREAKPOINTS.tabletMax}) {
    font-size: 3rem;
    line-height: 6rem;
  }

  @media (max-width: ${BREAKPOINTS.mobileMax}) {
    font-size: 2.5rem;
    line-height: 5rem;
  }
`;

const ContentWrapper = styled.div`
  margin: 40px auto 0;
  max-width: 1000px;
  width: 90%;

  a {
    font-weight: 700;
  }

  @media (max-width: ${BREAKPOINTS.twoColumnBreakpoint}) {
    margin: 20px auto 0;
  }
`;

const PrivacyPolicy = () => {
  return (
    <Wrapper>
      <ContactH1>Privacy Policy</ContactH1>
      <ContentWrapper>
        <TextBox>
          suebolandcoaching.com (“I” “us” “we” “our”) operates the
          https://suebolandcoaching.com / website (the Service)
        </TextBox>
        <TextBox>
          Your privacy is very important to me and I am committed to respecting
          and protecting your privacy. I will respect any personal data you
          share with me and keep it safe.
        </TextBox>
        <TextBox>
          The policy provides information about what information is collected,
          how it is used and how I take care of it.
        </TextBox>
        <TextBox>
          The policy will be updated from time to time, so please review
          regularly.
        </TextBox>
        <TextBox>
          I will collect, store and use any information you provide in
          accordance with the Data Protection Act 2018 and UK General Data
          Protection Guidelines (GDPR).
        </TextBox>

        <TextBox>The policy explains:</TextBox>
        <TextBox>
          <ul>
            <li>Who I am</li>
            <li>What data I collect </li>
            <li>Why I collect data</li>
            <li>How I collect data</li>
            <li>How I use data</li>
            <li>How the data is stored </li>
            <li>Types of data collected </li>
            <li>Working with other services and platforms</li>
            <li>Privacy Policies of other websites</li>
            <li>Children's Privacy Policy</li>
            <li>Your data protection rights</li>
            <li>What cookies are and how I use cookies</li>
            <li>Changes to the privacy policy</li>
            <li>How to contact me</li>
            <li>How to contact the appropriate authorities</li>
          </ul>
        </TextBox>
        <TextBox>
          <Bold>Who I am:</Bold>
        </TextBox>

        <TextBox>Sue Boland Transformational Life Coach </TextBox>

        <TextBox>
          <Bold>What data I collect:</Bold>
        </TextBox>

        <TextBox>
          I only collect data that you provide. This comprises of:{" "}
        </TextBox>

        <TextBox>
          Personal Data: if you make an inquiry through the contact form, social
          media or email me directly, I will take your personal identification
          details, which include your first name and surname. I will take your
          email address and contact number. I will take any relevant information
          you provide to me in the contact form, email or messaging
          correspondence.
        </TextBox>

        <TextBox>
          If you become a client of mine I will take relevant details during or
          after our sessions, to enable me to deliver the best possible service
          to you.
        </TextBox>

        <TextBox>
          Transaction data: I will take a record of payments from you.
        </TextBox>

        <TextBox>
          <Bold>Why I collect data:</Bold>
        </TextBox>

        <TextBox>
          I collect information from you, in order to allow me to provide you
          with the service you have requested. I need to know this data in order
          to provide my services to you.
        </TextBox>

        <TextBox>
          By using this Service, you agree to the collection and use of
          information in accordance with this Policy.
        </TextBox>

        <TextBox>
          <Bold>How I collect data:</Bold>
        </TextBox>

        <TextBox>
          Direct interactions: enquiries about the service via my contact form
          on my website, or through direct e-mail correspondence, by phone or
          otherwise (example social media).
        </TextBox>
        <TextBox>
          Feedback forms: feedback forms received about the service and
          submitted through email to me.
        </TextBox>

        <TextBox>
          <Bold>How long I will keep your personal data:</Bold>
        </TextBox>

        <TextBox>
          Data will be held for no longer than is necessary, in light of the
          reason for which it was collected.
        </TextBox>
        <TextBox>
          For tax purposes, the law requires that I keep basic information about
          customers' identity including contact and payment information for six
          years after they stop being customers.
        </TextBox>

        <TextBox>
          <Bold>How I use data:</Bold>
        </TextBox>

        <TextBox>
          I will only use your data for the purpose of which it is collected. I
          will use it to deliver my service to you.
        </TextBox>

        <TextBox>
          I will not share your data with any person or third party, unless I am
          required by Law to do so, or where I have gained your consent to do
          so.
        </TextBox>

        <TextBox>
          <Bold>How I store your data</Bold>
        </TextBox>

        <TextBox>
          Keeping your personal information safe is important to me. I have
          implemented appropriate physical, technical and managerial measures to
          protect the information I hold from improper access, use, alteration,
          disclosure, destruction and loss.
        </TextBox>

        <TextBox>
          I will securely store your data in electronic files with security
          measures in place.
        </TextBox>
        <TextBox>
          Please note that no method of transmission over the internet, or
          method of electronic storage is 100 % secure, but I will strive to
          protect your personal data.
        </TextBox>

        <TextBox>
          <Bold>Types of Data collected:</Bold>
        </TextBox>

        <TextBox>Personal Data which may include, but not limited to:</TextBox>
        <TextBox>
          <ul>
            <li>First name and last name</li>
            <li>E-mail address </li>
            <li>Contact number</li>
            <li>Payment information</li>
          </ul>
        </TextBox>

        <TextBox>
          <Bold>Working with other services and platforms</Bold>
        </TextBox>

        <TextBox>
          I use third party companies or individuals to facilitate the Service
          (Service Providers) on my behalf or to perform Service related
          services. These third parties have access to your personal Data only
          to perform these tasks and are obligated not to disclose or use it for
          any other purpose.
        </TextBox>

        <TextBox>
          <Bold>Privacy Policies of other websites</Bold>
        </TextBox>

        <TextBox>
          The website contains links to other websites that are not operated by
          us. This Privacy Policy applies only to this website. If you click on
          a third party link, you will be directed to that Third Party’s site.
          You are advised to review the Privacy Policy of each site you visit.
        </TextBox>

        <TextBox>
          I have no control over and do not assume any responsibility for the
          content, Privacy Policies or practices of any third party sites or
          services.
        </TextBox>

        <TextBox>
          <Bold>Children’s Privacy Policy</Bold>
        </TextBox>

        <TextBox>
          This Service does not address anyone under the age of 18. I will not
          knowingly collect Personal Data from any one under the age of 18. If
          you are a parent or guardian and become aware that your child has
          given me their Personal Data, please contact me and I will take steps
          to remove the information from the servers.
        </TextBox>

        <TextBox>
          <Bold>Transfer of Data</Bold>
        </TextBox>

        <TextBox>
          Your personal Data may be transferred or held on computers outside of
          your state, province or country, or other governmental jurisdiction,
          where data protection laws differ to those of your jurisdiction. If
          you are located outside of the UK and provide information to me,
          please note that your data will be transferred and processed in the
          UK.
        </TextBox>

        <TextBox>
          Your consent to this Policy and submission of information to me,
          confirms your agreement to the transfer.
        </TextBox>

        <TextBox>
          <Bold>What are Cookies</Bold>
        </TextBox>
        <TextBox>
          Cookies are files with small amounts of Data which may include an
          anonymous unique identifier. They are sent to your browser and stored
          on your device. They can help to monitor usage, manage security, and
          identify repeat visitors so the service can be analyzed and improved.
        </TextBox>

        <TextBox>
          <Bold>How do I use cookies?</Bold>
        </TextBox>
        <TextBox>This website does not use Cookies.</TextBox>

        <TextBox>
          <Bold>Changes to the Privacy Policy</Bold>
        </TextBox>

        <TextBox>
          The Policy will be reviewed annually and updated as necessary. I will
          post any updated, or new Privacy Policy on this website. I will update
          the effective date at the top of the Policy, following any updates or
          changes made.
        </TextBox>

        <TextBox>
          <Bold>Your Data Protection Rights</Bold>
        </TextBox>

        <TextBox>
          <ul>
            <li>
              Your right of access - You have the right to ask us for copies of
              your personal information.
            </li>
            <li>
              Your right to rectification - You have the right to ask to rectify
              personal information you think is inaccurate. You also have the
              right to ask me to complete information you think is incomplete.
            </li>
            <li>
              Your right to erasure - You have the right to ask me to erase your
              personal information in certain circumstances.
            </li>
            <li>
              Your right to restriction of processing - You have the right to
              ask me to restrict the processing of your personal information in
              certain circumstances.
            </li>
            <li>
              Your right to object to processing - You have the right to object
              to the processing of your personal information in certain
              circumstances.
            </li>
            <li>
              Your right to data portability - You have the right to ask me to
              transfer the personal information you gave me to another
              organization, or to you, in certain circumstances.
            </li>
            <li>
              You are not required to pay any charge for exercising your rights.
              If you make a request, we have one month to respond to you.
            </li>
          </ul>
        </TextBox>

        <TextBox>
          <Bold>Contact me</Bold>
        </TextBox>

        <TextBox>
          If you have any questions about the Privacy Policy, please contact me
          on my email{" "}
          <a href="mailto:hello@suebolandcoaching.com">
            hello@suebolandcoaching.com
          </a>
        </TextBox>

        <TextBox>
          <Bold>
            How to contact the appropriate authorities or make a complaint
          </Bold>
        </TextBox>

        <TextBox>
          You have any concerns about how I use your personal information, you
          can make a complaint to me at{" "}
          <a href="mailto:hello@suebolandcoaching.com">
            hello@suebolandcoaching.com
          </a>
        </TextBox>

        <TextBox>
          You can also complain to the ICO if you are unhappy with how your data
          has been used.
        </TextBox>
        <TextBox>The ICO’s address is:</TextBox>

        <TextBox>
          Information Commissioner's Office Wycliffe House
          <br />
          Office Wycliffe House
          <br />
          Water Lane Wilmsloe,
          <br /> Cheshire,
          <br /> SK9 5AF
        </TextBox>

        <TextBox>
          Helpline number - 0303 123 1113
          <br />
          ICO website -{" "}
          <a href="https://www.ico.org.uk/">https://www.ico.org.uk/</a>
        </TextBox>
      </ContentWrapper>
    </Wrapper>
  );
};

export default PrivacyPolicy;
