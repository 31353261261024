import React, { useEffect } from "react";
import styled from "styled-components";
import { BREAKPOINTS, COLORS } from "constants/style";
import MobileNavLinks from "components/Header/MobileNavLinks";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const StyledMenu = styled.nav<{ open: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background: ${COLORS.brown};
  height: 100vh;
  text-align: left;
  padding: 50px 2rem 2rem;
  position: absolute;
  top: 0;
  left: 0;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};
  transition: transform 0.3s ease-in-out;
  z-index: 10;

  @media (max-width: ${BREAKPOINTS.tabletMax}) {
    width: 100%;
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  li {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 1rem 0;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: ${COLORS.white};
    text-decoration: none;
    transition: color 0.3s linear;

    ul {
      margin-left: 20px;
      padding: 10px 0;

      li {
        font-size: 1.25rem;
        line-height: 1.6rem;
        letter-spacing: .2rem;
        width: min-content;
      }
    }

    @media (max-width: ${BREAKPOINTS.tabletMax}) {
      font-size: 1.5rem;
      line-height: 1.8rem;
      text-align: left;
      padding: 0.8rem 0;
    }
  }
`;

const MobileNav = ({ open, setOpen }: Props) => {
  useEffect(() => {
    if (open) {
      if (typeof window != "undefined" && window.document) {
        document.body.style.overflow = "hidden";
      }
    } else {
      document.body.style.overflow = "unset";
    }
  }, [open]);

  return (
    <StyledMenu open={open}>
      <ul>
        <MobileNavLinks
          onClickHandler={() => setOpen(!open)}
          linkColor={COLORS.white}
          showUnderline
        />
      </ul>
    </StyledMenu>
  );
};

export default MobileNav;
