import Banner from "components/shared/Banner";
import HorizontalRule from "components/shared/HorizontalRule";
import SmallImageHeader from "components/shared/SmallImageHeader";
import ServicesHeader from "images/services-header.jpg";
import CoachingHelpsWith from "./CoachingHelpsWith";
import HowItWorks from "./HowItWorks";
import WhatToExpect from "./WhatToExpect";
import WhoICoach from "./WhoICoach";
import WorkingWithMe from "./WorkingWithMe";

const Services = () => {
  const header = "1:1 Coaching";
  const subText =
    "I provide deep coaching, which might feel challenging as we delve into the deepest level of your being, but will lead to longer lasting transformation, and forward movement to embody new ways of being and doing in the world.";

  return (
    <>
      <SmallImageHeader
        image={ServicesHeader}
        imageAlt="Testimonials"
        header={header}
        subText={subText}
      />
      <HowItWorks />
      <Banner backgroundColor="transparent">
        All I ask is that you are committed to the process, feel ready to go on
        the transformational path, and you are ready to take action.
      </Banner>
      <CoachingHelpsWith />
      <HorizontalRule padded/>
      <WhoICoach />
      <HorizontalRule padded/>
      <WorkingWithMe />
      <HorizontalRule padded/>
      <WhatToExpect />
    </>
  );
};

export default Services;
