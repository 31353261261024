import { BREAKPOINTS, COLORS } from "constants/style";
import { CursiveH2, UnderlineStyle, TextBox } from "helpers/Typography";
import styled from "styled-components";

const Wrapper = styled.div`
  margin: 150px auto 0;
  width: 80%;

  @media (max-width: ${BREAKPOINTS.mobileMax}) {
    margin: 100px auto 0;
  }
`;

export const SectionHeader = styled.h2`
  font-family: "Montserrat";
  font-size: 3.25rem;
  line-height: 3.6rem;
  margin: 0 0 1.35rem;
  text-transform: uppercase;

  @media (max-width: ${BREAKPOINTS.mobileMax}) {
    font-size: 2.25rem;
    line-height: 2.6rem;
  }
`;

const H2PaddingWrapper = styled.div`
  margin: 0 auto;
  padding: 0 0 20px;
  width: fit-content;
`;

const StepContainer = styled.div`
  margin: 50px auto 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 50px;
  justify-content: center;
  align-items: stretch;

  @media (max-width: ${BREAKPOINTS.tabletMax}) {
    flex-direction: column;
    align-items: center;
  }
`;

const Step = styled.div`
  max-width: 500px;
  background-color: ${COLORS.beige};
  padding: 50px 50px 30px;

  @media (max-width: ${BREAKPOINTS.mobileMax}) {
    padding: 50px 30px 30px;
  }

  @media (max-width: ${BREAKPOINTS.mobileSmallMax}) {
    padding: 50px 20px 30px;
  }
`;

const HowItWorks = () => {
  return (
    <Wrapper>
      <H2PaddingWrapper>
        <SectionHeader>How it works</SectionHeader>
        <UnderlineStyle />
      </H2PaddingWrapper>
      <StepContainer>
        <Step>
          <CursiveH2>Step 1</CursiveH2>
          <TextBox>
            We will begin with a complementary clarity call which usually takes
            about 30 minutes.
          </TextBox>
          <TextBox>
            The purpose of the clarity call is to explore your current
            circumstance, and what the challenges are for you, and to determine
            if we are aligned as a good fit to work together. We will discuss
            what it is you deeply desire in any area of your life, and the
            changes you would like to see.
          </TextBox>
        </Step>
        <Step>
          <CursiveH2>Step 2</CursiveH2>
          <TextBox>
            We will work together for 6 months, with 2 sessions a month. If you
            decide you want coaching for longer than a 6 month period, the
            package can be extended.
          </TextBox>
          <TextBox>
            I will provide support between sessions through email, whatsapp,
            voice notes and accountability so you are getting the most out of
            this coaching journey, and you feel supported on your path.
          </TextBox>
        </Step>
      </StepContainer>
    </Wrapper>
  );
};

export default HowItWorks;
