import styled from "styled-components";
import { BREAKPOINTS, COLORS } from "../constants/style";

export const CursiveH1 = styled.h1`
  font-family: "Amalfi Coast";
  font-size: 3.25rem;
  line-height: 7.25rem;
  margin: 0 0 1.5rem;

  @media (max-width: ${BREAKPOINTS.tabletMax}) {
    font-size: 3.75vw;
    line-height: 10vw;
  }

  @media (max-width: ${BREAKPOINTS.mobileMax}) {
    font-size: 7vw;
    line-height: 17vw;
  }
`;

export const CursiveH2 = styled.h2`
  font-family: "Amalfi Coast";
  font-size: 2.25rem;
  line-height: 5rem;
  margin: 0 0 1.5rem;
  text-align: center;

  @media (max-width: ${BREAKPOINTS.mobileMax}) {
    font-size: 2rem;
    line-height: 4rem;
  }
`;

export const H2 = styled.h2`
  font-family: "Montserrat";
  font-size: 2.25rem;
  line-height: 2.6rem;
  margin: 0 0 1.35rem;
  text-transform: uppercase;
`;

export const H3 = styled.h3`
  font-family: "Montserrat";
  font-size: 1.35rem;
  line-height: 2rem;
  margin: 0 0 1.35rem;
  text-transform: uppercase;
`;

export const H4 = styled.h4`
  font-family: "Montserrat";
  font-size: 1.15rem;
  line-height: 1.4rem;
  text-transform: uppercase;
  font-weight: 300;
`;

export const TextBox = styled.div<{ heavy?: boolean }>`
  font-size: 1.5rem;
  line-height: 2.25rem;
  font-family: "EB Garamond";
  text-align: left;
  margin: 0 0 2rem;
  ${(props) =>
    props.heavy &&
    `
    font-weight: 700;
  `}

  @media (max-width: ${BREAKPOINTS.mobileMax}) {
    text-align: left;
  }
`;

export const Author = styled.div`
  font-family: "Amalfi Coast";
  font-size: 1.5rem;
  line-height: 2rem;
`;

export const UnderlineStyle = styled.div`
  background: ${COLORS.brown};
  height: 2px;
  width: 70%;
  margin: 0 auto;
`;

export const Bold = styled.span`
  font-weight: 700;
`;

export const ErrorText = styled.p`
  font-family: "Montserrat";
  font-size: 1.15rem;
  line-height: 1.5rem;
  color: red;
  margin: 15px 0 0;
  text-align: left;
`;

export const SuccessfulText = styled.p`
  font-family: "Montserrat";
  font-size: 1.6rem;
  line-height: 2rem;
  color: ${COLORS.brown};
  margin: 35px 0 0;
  text-align: left;

  @media (max-width: ${BREAKPOINTS.mobileMax}) {
    margin: 20px 0 0;
  }
`;
