import { NavBarLink } from "constants/navlinks";
import { COLORS } from "constants/style";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

interface SubMenu {
  text: string;
  path: string;
  show: boolean;
}

const Submenu = styled.div<{ isActive: boolean }>`
  display: ${(props) => (props.isActive ? "flex" : "none")};
  position: absolute;
  z-index: 5;
  background-color: ${COLORS.white};
  padding: 15px 5px;
  box-shadow: -4px 8px 10px rgba(0, 0, 0, 0.2);

  ul {
    list-style-type: none;
    text-decoration: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    li {
      display: inline-block;
      text-transform: uppercase;
      margin: 1rem 1rem;
      font-size: 1rem;
      line-height: 20px;
      font-weight: 500;
      letter-spacing: 1px;
    }
  }
`;

const Dropdown = ({
  isActive,
  submenus,
  linkColor,
  showUnderline,
}: {
  isActive: boolean;
  submenus: SubMenu[];
  linkColor: string;
  showUnderline?: boolean;
}) => {
  const location = useLocation();
  return (
    <Submenu isActive={isActive}>
      <ul>
        {submenus.map((s) => (
          <li key={s.path}>
            <NavBarLink
              linkColor={linkColor}
              currentPage={showUnderline && s.path === location.pathname}
            >
              <Link to={s.path}>{s.text}</Link>
            </NavBarLink>
          </li>
        ))}
      </ul>
    </Submenu>
  );
};

export default Dropdown;
