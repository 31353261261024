import { TextBox } from "helpers/Typography";
import styled from "styled-components";
import { ReactComponent as Email } from "images/icons/sue-email.svg";

const CopyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 15px auto;
`;

const EmailWrapper = styled.div`
  max-width: 400px;
  margin: 0 0 2rem;
`;

const Copy = () => {
  return (
    <CopyWrapper>
      <TextBox>
        If you are interested in coaching and would like to book a 30 minute
        clarity call with me, or you have questions, please complete the contact
        form or email me directly. I would be more than happy to answer any
        questions you may have.
      </TextBox>
      <TextBox>I will contact you as soon as possible.</TextBox>
      <TextBox>Looking forward to hearing from you!</TextBox>
      <EmailWrapper>
        <Email />
      </EmailWrapper>
    </CopyWrapper>
  );
};

export default Copy;
