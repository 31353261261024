import HeroImage from "components/Header/HeroImage";
import StorySection from "pages/Home/MyStory/StorySection";
import Banner from "components/shared/Banner";
import { BREAKPOINTS, COLORS } from "constants/style";
import styled from "styled-components";
import ServicesSection from "./Services/ServicesSection";
import HorizontalRule from "components/shared/HorizontalRule";
import TestimonialsSection from "./Testimonials/TestimonialsSection";
import HeaderTwo from "images/header-two.jpg";
import HeaderTwoWide from "images/header-two-wide.jpg";
import HeaderTwoMobile from "images/header-two-mobile.jpg";

export const HeaderTextContainer = styled.div`
  width: 45%;
  max-width: 600px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: 15%;
  position: absolute;
  margin-top: 50px;
  font-color: ${COLORS.white};

  @media (max-width: ${BREAKPOINTS.tabletMax}) and (orientation: portrait) {
    width: 460px;
    justify-content: flex-start;
    max-width: none;
    margin: 0 auto;
    padding: 60px 0;
    left: auto;
    position: relative;
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}) {
    width: 460px;
  }

  @media (max-width: ${BREAKPOINTS.mobileMax}) {
    justify-content: space-between;
    width: 310px;
    max-width: none;
    margin: 0 auto;
    padding: 60px 0 50px;
    left: auto;
    position: relative;
  }

  @media (max-width: ${BREAKPOINTS.mobileSmallMax}) {
    width: 100%;
  }
`;

const H1 = styled.h1`
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 4rem;
  line-height: 5rem;
  margin: 0 0 1.5rem;
  text-transform: uppercase;
  color: ${COLORS.white};

  @media (max-width: ${BREAKPOINTS.tabletMax}) {
    font-size: 3.5rem;
    line-height: 4.25rem;
  }

  @media (max-width: ${BREAKPOINTS.mobileMax}) {
    font-size: 2.25rem;
    line-height: 3rem;
  }

  @media (max-width: ${BREAKPOINTS.mobileSmallMax}) {
    font-size: 2rem;
    line-height: 2.75rem;
    margin: 0 0 1rem;
  }
`;

const Tagline = styled.p`
  font-family: "Photograph Signature";
  font-size: 5rem;
  line-height: 5rem;
  margin: 0 0;
  color: ${COLORS.white};

  @media (max-width: ${BREAKPOINTS.mobileMax}) {
    font-size: 3rem;
    line-height: 3rem;
  }

  @media (max-width: ${BREAKPOINTS.tabletMax}) {
    font-size: 4rem;
    line-height: 3.25rem;
  }

  @media (max-width: ${BREAKPOINTS.mobileSmallMax}) {
    font-size: 2.25rem;
    line-height: 2rem;
  }
`;

const Home = () => {
  return (
    <>
      <HeroImage
        imgDesktop={{img: HeaderTwo, position: "right center"}}
        imgWide={{img: HeaderTwoWide, position: "right top"}}
        imgMobile={{img: HeaderTwoMobile, position: "right bottom"}}
      >
        <HeaderTextContainer>
          <H1>Transformative Life Coaching</H1>
          <Tagline>There are infinite possibilities</Tagline>
        </HeaderTextContainer>
      </HeroImage>
      <Banner>
        My purpose is to support people to heal, grow and transform, so they can
        live purposefully in their power, and lead fulfilling, happy and
        meaningful lives.
      </Banner>
      <StorySection />
      <HorizontalRule padded/>
      <ServicesSection />
      <TestimonialsSection />
    </>
  );
};

export default Home;
