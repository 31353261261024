import { COLORS } from "constants/style";
import React from "react";
import styled from "styled-components";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const StyledBurger = styled.button<{ open: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 32px;
  height: 30px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 20;

  ${({ open }) =>
    open &&
    `
      position: absolute;
      top: 5%;
      right: 25px;
    `}

  &:focus {
    outline: none;
  }

  div {
    width: 32px;
    height: 0.25rem;
    background: ${({ open }) => (open ? COLORS.white : COLORS.brown)};
    border-radius: 10px;
    position: relative;
    transition: all 0.35s ease, opacity 0.25s ease;

    :first-child {
      transform: ${({ open }) =>
        open && "translateY(10px) translateX(0) rotate(45deg)"};
    }

    :nth-child(2) {
      opacity: ${({ open }) => open && "0"};
    }

    :nth-child(3) {
      transform: ${({ open }) =>
        open && "translateY(-10px) translateX(0) rotate(-45deg)"};
    }
  }
`;

const HamburgerMenu = ({ open, setOpen }: Props) => {
  return (
    <StyledBurger open={open} onClick={() => setOpen(!open)}>
      <div />
      <div />
      <div />
    </StyledBurger>
  );
};

export default HamburgerMenu;
