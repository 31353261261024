import { BREAKPOINTS, COLORS } from "constants/style";
import { H2, UnderlineStyle } from "helpers/Typography";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 80%;
  max-width: 1000px;
  margin: 100px auto;
  background-color: ${COLORS.lightGreen};
  padding: 100px;

  @media (max-width: ${BREAKPOINTS.tabletMax}) {
    padding: 75px 50px;
  }

  @media (max-width: ${BREAKPOINTS.mobileMax}) {
    padding: 50px 30px;
  }

  @media (max-width: ${BREAKPOINTS.mobileSmallMax}) {
    padding: 50px 20px;
  }
`;

const H2PaddingWrapper = styled.div`
  margin: 0 auto;
  padding: 0 0 20px;
  width: fit-content;
`;

const ListWrapper = styled.div`
  margin-top: 40px;
  text-align: left;
  ul {
    font-family: "EB Garamond";
    font-size: 1.5rem;
    line-height: 2rem;
  }
  li {
    margin: 20px 0;
  }

  @media (max-width: ${BREAKPOINTS.mobileMax}) {
    ul {
      padding-inline-start: 20px;
    }
  }
`;

const WhatToExpect = () => {
  return (
    <Wrapper>
      <H2PaddingWrapper>
        <H2>What to expect during our sessions</H2>
        <UnderlineStyle />
      </H2PaddingWrapper>
      <ListWrapper>
        <ul>
          <li>
            Expect someone who coaches from the heart, spirit and life
            experience.
          </li>
          <li>
            A safe space where we will go to the heart of the matter, together.
          </li>
          <li>
            Deep coaching where you feel safe and held to release your emotions.
          </li>
          <li>
            Coaching conversations where you can talk about that “something” you
            have never talked about before.
          </li>
          <li>
            As long as you are committed to the process; expect mental shifts,
            as we journey together.
          </li>
          <li>
            A coach who is fully present so that greater truths can arise,
            bringing clarity and a sense of freedom to explore and examine your
            thoughts.
          </li>
          <li>
            Healing moments, releasing deeply rooted fears or limiting beliefs.
          </li>
          <li>
            Visioning together, self examination, goal setting, generating
            motivation for forward action and change.
          </li>
          <li>
            Expect shifts from a fixed mindset to a growth mindset. New
            perspectives.
          </li>
          <li>
            Mindfulness practices to bring a sense of peace and calmness to your
            inner world.
          </li>
          <li>
            Forward plans so you progress on the path to achieve what you came
            here to achieve.
          </li>
          <li>
            A coach who is curious and listens deeply. Non judgemental, kind and
            calm, but has a profound internal strength. Expect to be challenged
            in order to support your goals.
          </li>
          <li>
            Exploration of harmful behaviors and how they might be holding you
            back from reaching your full potential in life.
          </li>
          <li>
            Examination of values and beliefs so you become truly aligned with
            your purpose in life.
          </li>
          <li>
            Deep coaching to help you move into wholeness, so your true self and
            soul can shine bright.
          </li>
          <li>
            Bringing the heart to the center of your awareness, by allowing
            moments of silence, reflection and inner contemplation.
          </li>
          <li>
            The use of breathwork to balance your mind, gain clarity and feel
            more empowered.
          </li>
          <li>
            The use of Tapping (EFT) to work through and release difficult
            emotions.
          </li>
        </ul>
      </ListWrapper>
    </Wrapper>
  );
};

export default WhatToExpect;
