import SplitSection, {
  SplitSectionPaddingWrapper,
} from "components/shared/SplitSection";
import { BREAKPOINTS } from "constants/style";
import { H2, TextBox, UnderlineStyle } from "helpers/Typography";
import WorkWithMeImg from "images/work-with-me-services.jpg";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Button from "components/shared/Button";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  @media (max-width: ${BREAKPOINTS.twoColumnBreakpoint}) {
    align-items: center;
    width: 100%;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;

  @media (max-width: ${BREAKPOINTS.twoColumnBreakpoint}) {
    width: 100%;
    height: 500px;
  }

  @media (max-width: ${BREAKPOINTS.mobileMax}) {
    height: 100%;
  }
`;

const Image = styled.img`
  height: 80%;
  position: absolute;
  box-shadow: 30px 30px 0px 0px #e9edc9;

  @media (max-width: ${BREAKPOINTS.mobileMax}) {
    width: 80%;
    height: auto;
    position: relative;
    box-shadow: 25px 25px 0px 0px #e9edc9;
  }

  @media (max-width: ${BREAKPOINTS.mobileSmallMax}) {
    width: 90%;
    box-shadow: 20px 20px 0px 0px #e9edc9;
  }
`;

const H2PaddingWrapper = styled.div`
  padding: 0 0 20px;
  width: fit-content;
`;

const StoryText = () => {
  return (
    <TextContainer>
      <H2PaddingWrapper>
        <H2>1:1 Coaching Package</H2>
        <UnderlineStyle />
      </H2PaddingWrapper>
      <Container>
        <TextBox>
          We will work together for 6 months with 60 - 75 minute sessions twice
          a month over zoom. I also offer 2 optional Tapping sessions (Emotional
          Freedom Technique) (EFT) within this package.
        </TextBox>
        <TextBox>
          You WON’T be doing this alone. I will be available on email and
          WhatsApp to provide support to you between sessions, empowering you on
          your Transformational journey.
        </TextBox>
        <TextBox>
          Coaching is a commitment to you and an investment into the changes you
          want to see in your life.
        </TextBox>
        <TextBox>
          Sessions can include holistic and healing techniques to support you on
          your journey, such as Tapping (EFT), Mindfulness, Meditation and
          Breathwork, Intuitive Guidance, Creative Visualisations along with
          Positive Psychology tools and techniques.
        </TextBox>
        <TextBox>
          Payment plans available. Get in touch to discuss your investment with
          me.
        </TextBox>
        <Link to="/contact">
          <Button text="Contact Me" />
        </Link>
      </Container>
    </TextContainer>
  );
};

const WorkingWithMe = () => {
  return (
    <SplitSectionPaddingWrapper>
      <SplitSection
        split="half"
        right={<StoryText />}
        left={
          <ImageContainer>
            <Image src={WorkWithMeImg} alt="About me" />
          </ImageContainer>
        }
      />
    </SplitSectionPaddingWrapper>
  );
};

export default WorkingWithMe;
