import React from "react";
import styled from "styled-components";
import { BREAKPOINTS, COLORS, SIZES } from "constants/style";

type Props = {
  children?: React.ReactNode;
  backgroundColor?: string;
};

const BannerContainer = styled.div<{
  bgColor?: string;
}>`
  background-color: ${(props) => props.bgColor || COLORS.green};
  font-family: "EB Garamond";
  color: ${COLORS.black};
  font-size: 2.75rem;
  line-height: 4.25rem;
  padding: 6rem 8rem;

  @media (max-width: ${BREAKPOINTS.tabletMax}) {
    font-size: 2.25rem;
    line-height: 3.2rem;
    padding: 6rem 2rem;
  }

  @media (max-width: ${BREAKPOINTS.mobileSmallMax}) {
    font-size: 2rem;
    line-height: 3rem;
  }
`;

const BannerInner = styled.div`
  max-width: ${SIZES.maxWidth};
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

const Banner = ({ children, backgroundColor }: Props) => {
  return (
    <BannerContainer bgColor={backgroundColor}>
      <BannerInner>{children}</BannerInner>
    </BannerContainer>
  );
};

export default Banner;
