import Dropdown from "components/Header/Dropdown";
import { NavBarLink, NAV_LINKS } from "constants/navlinks";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as ChevronDown } from "images/icons/chevron-down.svg";
import styled from "styled-components";

interface Props {
  linkColor: string;
  showUnderline?: boolean;
}

const ChevronWrapper = styled.div`
  margin-left: 4px;
  display: inline-block;
  svg {
    height: 20px;
    width: 20px;
  }
`;

const NavLinks = ({ linkColor, showUnderline }: Props) => {
  const location = useLocation();
  const [activeMenuItem, setActiveMenuItem] = useState("");

  return (
    <>
      {NAV_LINKS.map((link) => {
        if (link.show) {
          return (
            <li
              key={link.path}
              onMouseOver={() => setActiveMenuItem(link.text)}
              onMouseLeave={() => setActiveMenuItem("")}
            >
              <NavBarLink
                linkColor={linkColor}
                currentPage={
                  showUnderline &&
                  (link.path === location.pathname.slice(1) ||
                    link.path === location.pathname)
                }
              >
                <Link to={link.path}>{link.text}</Link>
                {link.sub && (
                  <ChevronWrapper>
                    <ChevronDown />
                  </ChevronWrapper>
                )}
              </NavBarLink>
              {link.sub && (
                <Dropdown
                  isActive={activeMenuItem === link.text}
                  submenus={link.sub}
                  linkColor={linkColor}
                  showUnderline={showUnderline}
                />
              )}
            </li>
          );
        } else return false;
      })}
    </>
  );
};

export default NavLinks;
