import { BREAKPOINTS, COLORS, SIZES } from "constants/style";
import { H2, UnderlineStyle } from "helpers/Typography";
import React from "react";
import styled from "styled-components";

export const SplitSectionPaddingWrapper = styled.div`
  padding: 10rem 2rem;

  @media (max-width: ${BREAKPOINTS.twoColumnBreakpoint}) {
    padding: 4.5rem 6rem;
  }

  @media (max-width: ${BREAKPOINTS.mobileMax}) {
    padding: 4.5rem 2rem;
  }
`;

const Wrapper = styled.div<{ bgColor?: string }>`
  background-color: ${(props) => props.bgColor || COLORS.white};
`;

const H2PaddingWrapper = styled.div`
  margin: 0 auto;
  padding: 0 0 20px;
  width: fit-content;
`;

interface GapProperties {
  column: number;
  row: number;
}

const Container = styled.div<{
  bgColor?: string;
  reverse?: boolean;
  title?: string;
  gap?: GapProperties;
}>`
  position: relative;
  display: flex;
  flex-direction: ${(props) => (props.reverse ? "row-reverse" : "row")};
  justify-content: space-around;
  background-color: ${(props) => props.bgColor || COLORS.white};
  gap: ${(props) => (props.gap ? `${props.gap.row}px` : "40px")};
  max-width: ${SIZES.maxWidth};
  margin: 0 auto;

  @media (max-width: ${BREAKPOINTS.twoColumnBreakpoint}) {
    flex-direction: column;
    align-items: center;
    gap: ${(props) => (props.gap ? `${props.gap.column}px` : "70px")};
  }
`;

const Section = styled.div<{ align?: string; width: string }>`
  width: ${({ width }) => width ?? "50"}%;

  @media (max-width: ${BREAKPOINTS.twoColumnBreakpoint}) {
    width: 80%;
  }

  @media (max-width: ${BREAKPOINTS.mobileMax}) {
    width: 100%;
  }
`;

type Split = "half" | "twoThirds";

type Props = {
  title?: string;
  left: React.ReactNode;
  right: React.ReactNode;
  bgColor?: string;
  split: Split;
  reverse?: boolean;
  gap?: GapProperties;
};

const SplitSection = ({
  title,
  left,
  right,
  bgColor,
  split,
  reverse,
  gap,
}: Props) => {
  return (
    <Wrapper bgColor={bgColor}>
      {title && (
        <H2PaddingWrapper>
          <H2>{title}</H2>
          <UnderlineStyle />
        </H2PaddingWrapper>
      )}
      <Container bgColor={bgColor} reverse={reverse} title={title} gap={gap}>
        <Section width={split === "half" ? "50" : "66"}>{left}</Section>
        <Section width={split === "half" ? "50" : "33"} align="flex-start">
          {right}
        </Section>
      </Container>
    </Wrapper>
  );
};

export default SplitSection;
