import HeroImageShort from "components/Header/HeroImageShort";
import Banner from "components/shared/Banner";
import Header from "images/mindfulness-header.jpg";
import Copy from "./Copy";

const Mindfulness = () => {
  return (
    <>
      <HeroImageShort
        imgDesktop={{ img: Header, position: "center" }}
        imgWide={{ img: Header, position: "center" }}
        imgMobile={{ img: Header, position: "center" }}
      >
        Mindfulness for Coaching
      </HeroImageShort>
      <Banner>
        “Mindfulness means paying attention in a particular way: on purpose, in the present moment, and non-judgmentally.” - Jon Kabat-Zinn
      </Banner>
      <Copy />
    </>
  );
};

export default Mindfulness;
