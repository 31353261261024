import Button from "components/shared/Button";
import HorizontalRule from "components/shared/HorizontalRule";
import SplitSection, {
  SplitSectionPaddingWrapper,
} from "components/shared/SplitSection";
import { TECHNIQUE_LINKS } from "constants/navlinks";
import { BREAKPOINTS } from "constants/style";
import { H2, H3, H4, TextBox } from "helpers/Typography";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as ChevronRight } from "images/icons/chevron-right.svg";

const AlignLeft = styled.div`
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const AlignCenter = styled.div`
  width: 100%;
  padding-left: 10%;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: ${BREAKPOINTS.twoColumnBreakpoint}) {
    padding-left: 0;
  }
`;

const TechniquesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
  align-items: flex-start;

  @media (max-width: ${BREAKPOINTS.twoColumnBreakpoint}) {
    max-width: 400px;
    width: 100%;
  }
`;

const TechniqueWrapper = styled.div`
  margin: 1rem 0;

  a {
    display: inline-flex;
    align-items: center;
    h4 {
      &:hover {
        font-weight: 700;
      }
    }
    
  }
`;

const CoachingContainer = styled.div`
  width: 85%;
  display: flex;
  flex-direction: column;

  @media (max-width: ${BREAKPOINTS.twoColumnBreakpoint}) {
    width: 100%;
  }
`;

const TechniqueSectionWrapper = styled.div`
  width: 100%;
`;

const HorizRuleWrapper = styled.div`
  width: 100%;
`;

const OneToOneCoaching = () => {
  return (
    <AlignLeft>
      <CoachingContainer>
        <H2>How I can help you grow</H2>
        <H3>1:1 Coaching</H3>
        <TextBox>
          Through my 1:1 coaching programme we will work together to get to the
          root of the issues, and create steps to move forward by setting goals
          and actions to work towards your desires. Using transformative,
          mindfulness and positive psychology coaching tools, as well as
          creative visualisation and guided meditations, we can delve into the
          core of your being in order to create internal shifts and lasting
          change on a soul level. We will also use energy work in the form of
          EFT to unlock difficult emotions and energy blocks that might be
          holding you back.
        </TextBox>
        <TextBox>
          By working together we will shift your mindset for lasting change and
          transform your life.
        </TextBox>
        <Link to="services">
          <Button text="1:1 Coaching" />
        </Link>
      </CoachingContainer>
    </AlignLeft>
  );
};

const ChevronWrapper = styled.span`
  margin-left: 4px;
  display: inline-block;
  svg {
    height: 12px;
    width: 12px;
  }
`;

const Technique = ({ path, text }: { path: string; text: string }) => {
  return (
    <TechniqueWrapper>
      <Link to={path}>
        <H4>
          {text}
          <ChevronWrapper>
            <ChevronRight />
          </ChevronWrapper>
        </H4>
      </Link>
    </TechniqueWrapper>
  );
};

const Techniques = () => {
  return (
    <AlignCenter>
      <H2>The core techniques:</H2>
      <TechniquesContainer>
        {TECHNIQUE_LINKS.map((sub, index) => {
          return (
            <TechniqueSectionWrapper key={sub.path}>
              <Technique path={sub.path} text={sub.text} />
              {index !== TECHNIQUE_LINKS.length - 1 && (
                <HorizRuleWrapper>
                  <HorizontalRule />
                </HorizRuleWrapper>
              )}
            </TechniqueSectionWrapper>
          );
        })}
      </TechniquesContainer>
    </AlignCenter>
  );
};

const ServicesSection = () => {
  return (
    <SplitSectionPaddingWrapper>
      <SplitSection
        split="half"
        left={<OneToOneCoaching />}
        right={<Techniques />}
      />
    </SplitSectionPaddingWrapper>
  );
};

export default ServicesSection;
