import StoryCopy from "pages/MyStory/StoryCopy";
import SmallImageHeader from "components/shared/SmallImageHeader";
import MoreAboutMe from "./MoreAboutMe";
import MyExperience from "./MyExperience";
import AboutMe from "images/my-story-header.jpg";

const Story = () => {
  const header = "Hello, I'm Sue!";
  const subText =
    "From a young age I experienced challenging life circumstances. However, this has left me with an expanded emotional capacity and fine attunement to the needs of others.";

  return (
    <>
      <SmallImageHeader
        image={AboutMe}
        imageAlt="About me"
        header={header}
        subText={subText}
      />
      <StoryCopy />
      <MoreAboutMe />
      <MyExperience />
    </>
  );
};

export default Story;
