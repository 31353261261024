import { BREAKPOINTS, COLORS } from "constants/style";
import styled from "styled-components";
import { useState } from "react";
import { TESTIMONIALS } from "helpers/testimonials";
import { parseMarkdown } from "helpers/markdown";
import { ReactComponent as ChevronLeft } from "images/icons/chevron-left.svg";
import { ReactComponent as ChevronRight } from "images/icons/chevron-right.svg";
import QuotationMark from "images/icons/quotation-marks.svg";
import { Author } from "helpers/Typography";
import { Link } from "react-router-dom";
import Button from "components/shared/Button";

const Wrapper = styled.div`
  width: 100%;
  background-color: ${COLORS.beige};
  padding: 100px 0;
`;

const TitleWrapper = styled.div`
  width: 500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  @media (max-width: ${BREAKPOINTS.mobileMax}) {
    width: 100%;
    max-width: 330px;
    padding: 10px;
    margin-bottom: 15px;
  }
`;

const TitleTop = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  font-family: Montserrat;
  font-size: 3rem;
  line-height: 3.5rem;
  text-transform: uppercase;

  @media (max-width: ${BREAKPOINTS.mobileMax}) {
    font-size: 2rem;
    line-height: 2.5rem;
  }
`;

const TitleBottom = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-family: "Amalfi Coast";
  font-size: 2.5rem;
  line-height: 3.5rem;

  @media (max-width: ${BREAKPOINTS.mobileMax}) {
    font-size: 1.5rem;
    line-height: 2.5rem;
  }
`;

const TestimonialWrapper = styled.div`
  transition: transform 0.3s ease-in-out;
`;

const TestimonialText = styled.div`
  position: relative;
  z-index: 1;
  margin: 0 auto;
  width: 80%;
  max-width: 800px;
  transition-property: width;
  transition-duration: 2s;
  transition-timing-function: ease;

  p {
    text-align: center;
    line-height: 2rem;
    font-size: 1.4rem;
    line-height: 2rem;
    font-family: "EB Garamond";
  }

  .pseudo-wrapper {
    position: absolute;
  }

  ::after {
    position: absolute;
    z-index: -1;
    content: "";
    -webkit-mask: url(${QuotationMark}) no-repeat 50% 50%;
    mask: url(${QuotationMark}) no-repeat 50% 50%;
    -webkit-mask-size: cover;
    mask-size: cover;
    background-color: ${COLORS.lightGrey};
    display: inline-block;
    width: 70px;
    height: 55px;
    left: -28px;
    top: -23px;
  }
`;

const NavWrapper = styled.div`
  margin: 0 auto 30px;
  width: 100px;
  display: flex;
  justify-content: space-between;
`;

const AuthorWrapper = styled.div`
  margin: 40px 0;
`;

const TestimonialsSection = () => {
  const [tIndex, setTIndex] = useState(0);
  const tLength = TESTIMONIALS.length;

  const prev = () => {
    setTIndex(tIndex === tLength - 1 ? 0 : tIndex + 1);
  };
  const next = () => {
    setTIndex(tIndex === 0 ? tLength - 1 : tIndex - 1);
  };

  return (
    <Wrapper>
      <TitleWrapper>
        <TitleTop>Testimonials</TitleTop>
        <TitleBottom>from happy clients</TitleBottom>
      </TitleWrapper>
      <TestimonialWrapper>
        <TestimonialText>
          <div id="pseudo-wrapper">
            {parseMarkdown(TESTIMONIALS[tIndex].quote)}
          </div>
        </TestimonialText>
        <AuthorWrapper>
          <Author>- {TESTIMONIALS[tIndex].name}</Author>
        </AuthorWrapper>
      </TestimonialWrapper>
      <NavWrapper>
        <ChevronLeft onClick={(e) => prev()} />
        <ChevronRight onClick={(e) => next()} />
      </NavWrapper>
      <Link to="testimonials">
        <Button text="Read more" />
      </Link>
    </Wrapper>
  );
};

export default TestimonialsSection;
