import { H3, TextBox } from "helpers/Typography";
import { TechniquesCTA, Wrapper } from "../Shared/Shared";

const Copy = () => {
  return (
    <Wrapper>
      <H3>What is Mindfulness?</H3>
      <TextBox>
        There are two aspects of Mindfulness which can be useful in coaching;
        mindful practice and mindful meditation.
      </TextBox>
      <TextBox>
        Mindful meditation is a practice where you will look inwards.
        Concentration is focused and particular. This can lead to an open and
        relaxed state of being within the coaching sessions, creating space for
        more deep reflection and conversation.
      </TextBox>
      <TextBox>
        Mindful practice is about incorporating Mindfulness into daily life and
        living. Bringing it into your transactions , way of thinking and doing.
        This leads to more self awareness and conscious living, which in turn
        can lead to greater control over your life.
      </TextBox>
      <H3>How can Mindfulness help you?</H3>
      <TextBox>
        Bringing Mindfulness into the coaching space, and outside of it, can be
        powerful in helping you to become more:
      </TextBox>
      <TextBox>
        <ul>
          <li>Present, reflective, introspective</li>
          <li>
            At peace and happy in your state of being and how you want to show
            up in the world
          </li>
          <li>Gain clarity and release of difficult emotions</li>
          <li>
            Leads to unlocking creativity and clarity of how you want to move
            forward
          </li>
          <li>Grounding</li>
          <li>Reduce your stress levels and anxiety</li>
        </ul>
      </TextBox>
      <H3>How does Mindfulness help with coaching?</H3>
      <TextBox>
        As a coach I practice my own Mindfulness and meditation daily. This
        helps me to bring the following to the coaching space:
      </TextBox>
      <TextBox>
        <ul>
          <li>
            More intention and attention - the intention of being present,
            listening, being open and non judgemental. I pay attention to follow
            it through in every session.
          </li>
          <li>Calmness</li>
          <li>A clear mind to focus on YOU and only YOU</li>
          <li>
            A strong intuition to help me guide and explore your challenges with
            you in the sessions
          </li>
          <li>
            Maintain balance and patience , regardless of the challenges
            presented
          </li>
          <li>
            Provide you with tools and approaches to help you on your journey
          </li>
        </ul>
      </TextBox>
      <TextBox>
        Let me help you use Mindfulness to progress on your journey to
        happiness.
      </TextBox>
      <TechniquesCTA />
    </Wrapper>
  );
};

export default Copy;
