import SmallImageHeader from "components/shared/SmallImageHeader";
import TestimonialsImg from "images/testimonials.jpg";
import { TESTIMONIALS } from "helpers/testimonials";
import TestimonialRow from "./TestimonialRow";
import styled from "styled-components";
import { BREAKPOINTS } from "constants/style";

const TestimonialWrapper = styled.div`
  width: 100%;
  margin: 100px 0;

  @media (max-width: ${BREAKPOINTS.mobileMax}) {
    margin: 30px 0;
  }

  @media (max-width: ${BREAKPOINTS.mobileSmallMax}) {
    margin: 0;
  }
`;

const Testimonials = () => {
  const header = "What my clients say";
  const subText =
    "Don’t just take it from me. Hear what my clients have to say about working with me, and how I’ve been able to help them reach their true potential.";
  

  return (
    <>
      <SmallImageHeader
        image={TestimonialsImg}
        imageAlt="Testimonials"
        header={header}
        subText={subText}
      />
      <TestimonialWrapper>
        {TESTIMONIALS.map((t, i) => (
          <TestimonialRow key={t.name} name={t.name} full={t.full} index={i}/>
        ))}
      </TestimonialWrapper>
    </>
  );
};

export default Testimonials;
