import styled from "styled-components";
import { COLORS } from "./style";

export interface NavLinksType {
  text: string;
  path: string;
  show: boolean;
}

export interface NavLinksWithSub extends NavLinksType {
  sub?: NavLinksType[];
}

export const TECHNIQUE_LINKS: NavLinksType[] = [
  {
    text: "Transformative Coaching",
    path: "/services/transformative-coaching",
    show: true,
  },
  {
    text: "Emotional Freedom Technique",
    path: "/services/eft",
    show: true,
  },
  {
    text: "Positive Psychology",
    path: "/services/positive-psychology",
    show: true,
  },
  {
    text: "Mindfulness Coaching",
    path: "/services/mindfulness",
    show: true,
  },
];

export const NAV_LINKS: NavLinksWithSub[] = [
  {
    text: "My Story",
    path: "my-story",
    show: true,
  },
  {
    text: "Work with me",
    path: "clients",
    show: true,
  },
  {
    text: "Services",
    path: "services",
    show: true,
    sub: TECHNIQUE_LINKS,
  },
  {
    text: "Testimonials",
    path: "testimonials",
    show: true,
  },
];

export const NavBarLink = styled.div<{
  currentPage?: boolean;
  linkColor: string;
  mobileView?: boolean;
}>`
  position: relative;
  width: fit-content;
  text-decoration: none;
  color: ${(props) => props.linkColor || COLORS.black};
  border-bottom: ${(props) => (props.currentPage ? `1.5px solid` : "none")};
  border-bottom-color: ${(props) =>
    props.mobileView ? COLORS.white : COLORS.brown};
  padding-bottom: ${(props) => (props.currentPage ? "5px" : "none")};
`;