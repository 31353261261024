import "./App.css";
import { Helmet } from "react-helmet";
import { Outlet } from "react-router-dom";
import Header from "components/Header/Header";
import Footer from "components/shared/Footer";

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>Sue Boland Transformative Life Coaching</title>
        <meta
          name="description"
          content="Unlock your full potential, overcome obstacles, and achieve meaningful personal and professional growth with Transformative Life Coaching."
        />
        <meta
          name="keywords"
          content="Transformative Life Coaching, Life Coaching, Emotional Freedom Technique, EFT, Tapping, Positive Psychology, Mindfulness, Mindfulness Coaching"
        />
        <meta name="author" content="Sue Boland" />
        <meta
          property="og:title"
          content="Sue Boland Transformative Life Coaching"
        />
        <meta
          property="og:description"
          content="Unlock your full potential, overcome obstacles, and achieve meaningful personal and professional growth with Transformative Life Coaching."
        />
        <meta
          property="og:image"
          content="https://suebolandcoaching.com/static/media/header-two.e3efe11129d25087310e.jpg"
        />
        <meta property="og:url" content="https://suebolandcoaching.com/" />
        <meta
          name="twitter:title"
          content="Sue Boland Transformative Life Coaching"
        />
        <meta
          name="twitter:description"
          content="Unlock your full potential, overcome obstacles, and achieve meaningful personal and professional growth with Transformative Life Coaching."
        />
        <meta
          name="twitter:image"
          content="https://suebolandcoaching.com/static/media/header-two.e3efe11129d25087310e.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Header />
      <main>
        <Outlet />
      </main>
      <Footer />
    </div>
  );
}

export default App;
