import styled from "styled-components";
import HeroImage from "components/Header/HeroImage";
import { BREAKPOINTS, COLORS } from "constants/style";
import HeaderOne from "images/header-one.jpg";
import HeaderOneMobile from "images/header-one-mobile.jpg";
import Banner from "components/shared/Banner";
import { H2, TextBox, UnderlineStyle } from "helpers/Typography";
import SplitSection from "components/shared/SplitSection";
import HeartIcon from "images/icons/heart.svg";
import Button from "components/shared/Button";
import { Link } from "react-router-dom";

const H1 = styled.h1`
  font-family: "Photograph Signature";
  font-weight: 500;
  font-size: 7rem;
  line-height: 15rem;
  margin: 0;
  color: ${COLORS.white};

  @media (max-width: ${BREAKPOINTS.tabletMax}) {
    font-size: 6.5rem;
    line-height: 15.25rem;
  }

  @media (max-width: ${BREAKPOINTS.mobileMax}) {
    font-size: 3.5rem;
    line-height: 5rem;
  }

  @media (max-width: ${BREAKPOINTS.mobileSmallMax}) {
    font-size: 2.75rem;
    line-height: 4rem;
  }
`;

export const HeaderTextContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding: 10%;

  @media (max-width: ${BREAKPOINTS.tabletMax}) and (orientation: portrait) {
    width: 100%;
    padding: 30% 10%;
    align-items: flex-end;
    justify-content: center;
  }

  @media (max-width: ${BREAKPOINTS.mobileMax}) {
    padding: 40% 10%;
  }
`;

export const HeaderTextWrapper = styled.div`
  padding: 0 50px;
  max-width: 600px;
  background-color: rgba(0, 0, 0, 0.5);
  height: fit-content;

  @media (max-width: ${BREAKPOINTS.mobileMax}) {
    padding: 20px;
  }
`;

const H2PaddingWrapper = styled.div`
  margin: 0 auto;
  padding: 0 0 20px;
  width: fit-content;
`;

const IntroTextWrapper = styled.div`
  max-width: 900px;
  margin: 100px auto;
  padding: 0 30px;

  p {
    text-align: center;
  }
`;

const SplitSectionWrapper = styled.div`
  background-color: ${COLORS.lightGrey};
  max-width: 1100px;
  width: 80%;
  margin: 100px auto;
  padding: 80px;

  ul {
    text-align: left;
    padding-inline-start: 14px;
  }

  li {
    margin: 30px 0;
    font-size: 1.25rem;
    line-height: 1.75rem;
    list-style: none;
    padding: 0px 0 0 35px;
    list-style: none;
    background-image: url(${HeartIcon});
    background-repeat: no-repeat;
    background-position: left 4px;
    background-size: 20px;
  }

  @media (max-width: ${BREAKPOINTS.mobileMax}) {
    padding: 70px 25px;

    ul {
      padding-inline-start: 0;
    }
  }

  @media (max-width: ${BREAKPOINTS.mobileSmallMax}) {
    padding: 70px 15px;
    width: 90%;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 30px;
`;

const CTAWrapper = styled.div`
  width: 90%;
  margin: 0 auto 100px;
`;

const LeftContent = () => {
  return (
    <ul>
      <li>
        Are you trying to find your way forward after a traumatic life event or
        series of life events?
      </li>
      <li>Are you searching for peace and happiness? Do you seek clarity?</li>
      <li>
        Have you experienced some kind of loss, suffering, difficult
        situation(s) or relationship breakdown, and not sure how to move
        forward?
      </li>
      <li>Do you want to make positive changes to your life?</li>
      <li>Is your life lacking purpose?</li>
    </ul>
  );
};

const RightContent = () => {
  return (
    <ul>
      <li>
        Are you demotivated, unfulfilled, burnt out or stuck in a situation that
        feels misaligned?
      </li>
      <li>You may lack confidence or feel as though you're not good enough.</li>
      <li>
        Are you ready to delve deep into your emotions, and find that beautiful
        place of self love which will lead you to a more fulfilling life?
      </li>
      <li>Do you have fears or limiting beliefs holding you back?</li>
      <li>Are you ready to let go of any suffering? </li>
    </ul>
  );
};

const WorkWithMe = () => {
  return (
    <>
      <HeroImage
        imgDesktop={{ img: HeaderOne, position: "right center" }}
        imgWide={{ img: HeaderOne, position: "right center" }}
        imgMobile={{ img: HeaderOneMobile, position: "center center" }}
      >
        <HeaderTextContainer>
          <HeaderTextWrapper>
            <H1>Work With Me</H1>
          </HeaderTextWrapper>
        </HeaderTextContainer>
      </HeroImage>
      <Banner>
        “The secret of change is to focus all of your energy not on fighting the
        old, but on building the new.” - Socrates
      </Banner>
      <IntroTextWrapper>
        <H2PaddingWrapper>
          <H2>Are you ready for a change?</H2>
          <UnderlineStyle />
        </H2PaddingWrapper>
        <TextBox>
          You know something needs to change, but you're not sure how to change
          things, or where to start. Maybe you are carrying emotional burdens
          from the past.
        </TextBox>
        <TextBox>
          Despite all of this you know you have the courage to make a change,
          and you are ready to commit to the process, understand why things
          haven't worked out in the past, and explore new ways of doing and
          being to find happiness, freedom, peace, or purpose - what you truly
          deserve in life.
        </TextBox>
        <TextBox>
          Imagine feeling calm, happy and fulfilled
          <br />
          Imagine feeling hopeful, energized and optimistic
          <br />
          Imagine feeling mentally strong, resilient and forward focused
          <br />
          Imagine feeling productive again, with clarity and vision
          <br />
          Imagine reaching your full potential
          <br />
          Imagine feeling at peace
          <br />
          Imagine feeling EXCITED about life again
        </TextBox>
        <TextBox>This is how you can feel after working with me</TextBox>
      </IntroTextWrapper>
      <SplitSectionWrapper>
        <SplitSection
          title="Does this sound like you?"
          split="half"
          left={<LeftContent />}
          right={<RightContent />}
          bgColor="transparent"
        />
      </SplitSectionWrapper>
      <CTAWrapper>
        <Banner>
          I encourage you to work with me, and I will take you on that journey
          to find true fulfillment and the happiness you deserve in life.
          <ButtonWrapper>
            <Link to="../contact">
              <Button text="Start your transformation" />
            </Link>
          </ButtonWrapper>
        </Banner>
      </CTAWrapper>
    </>
  );
};

export default WorkWithMe;
