export const COLORS = {
  green: "#CCD5AE",
  lightGreen: "#E9EDC9",
  lightYellow: "#FEFAE0",
  beige: "#FAEDCD",
  brown: "#D4A373",
  white: "#FFFFFF",
  black: "#000000",
  darkGrey: "#333333",
  lightGrey: "#DDD"
};

export const BREAKPOINTS = {
  mobileSmallMax: "300px",
  mobileMax: "640px",
  twoColumnBreakpoint: "1024px",
  tabletMax: "1200px",
}

export const SIZES = {
  maxWidth: "1200px"
}
