import { ReactNode } from "react";
import styled from "styled-components";
import { BREAKPOINTS, COLORS } from "constants/style";
import { useMediaQuery } from "react-responsive";

export const Hero = styled.div<{
  image: string;
  position: string;
}>`
  position: relative;
  background-image: url(${(props) => props.image});
  width: 100%;
  height: 40vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: ${(props) => props.position};
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(141, 141, 141, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.div`
  font-family: "Photograph Signature";
  font-size: 3.5rem;
  line-height: 4rem;
  color: ${COLORS.white};
  background-color: rgba(141, 141, 141, 0.3);
  border: 5px solid #d4a373;
  padding: 20px;
  max-width: 80%;

  @media (min-width: ${BREAKPOINTS.mobileMax}) {
    font-family: "Photograph Signature";
    font-size: 5rem;
    line-height: 5rem;
    padding: 40px 80px;
  }
`;

type ImageProps = {
  img: string;
  position: string;
};

type Props = {
  imgDesktop: ImageProps;
  imgWide: ImageProps;
  imgMobile: ImageProps;
  children: ReactNode;
};

const HeroImageShort = ({
  imgDesktop,
  imgMobile,
  imgWide,
  children,
}: Props) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${BREAKPOINTS.mobileMax})`,
  });
  const isTablet = useMediaQuery({
    query: `(max-width: ${BREAKPOINTS.tabletMax})`,
  });
  const isLandscape = useMediaQuery({ query: "(orientation: landscape)" });

  return (
    <>
      {isMobile ? (
        <Hero image={imgMobile.img} position={imgMobile.position}>
          <Wrapper>
            <Title>{children}</Title>
          </Wrapper>
        </Hero>
      ) : isTablet || (isMobile && isLandscape) ? (
        <Hero image={imgDesktop.img} position={imgDesktop.position}>
          <Wrapper>
            <Title>{children}</Title>
          </Wrapper>
        </Hero>
      ) : (
        <Hero image={imgWide.img} position={imgWide.position}>
          <Wrapper>
            <Title>{children}</Title>
          </Wrapper>
        </Hero>
      )}
    </>
  );
};

export default HeroImageShort;
