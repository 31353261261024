import styled from "styled-components";
import { H2, UnderlineStyle } from "helpers/Typography";
import { ReactComponent as HeartIcon } from "images/icons/heart.svg";

const Wrapper = styled.div`
  width: 80%;
  margin: 125px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 100px;
`;

const SectionWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
`;

const BlurbWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 50px;
`;

const Blurb = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 260px;
  align-items: center;
  text-align: center;
  font-family: "Montserrat";
  font-size: 1.25rem;
  line-height: 2rem;
`;

const H2Wrapper = styled.div`
  width: fit-content;
  margin: 0 auto 50px;
`;


const MyExperience = () => {
  return (
    <Wrapper>
      <SectionWrapper>
        <H2Wrapper>
          <H2>I’VE WORKED WITH YOUNG PEOPLE...</H2>
          <UnderlineStyle />
        </H2Wrapper>
        <BlurbWrapper>
          <Blurb>
            <HeartIcon />
            <p>
              In psychologically informed environments, within residential care,
              who had experienced trauma and abuse
            </p>
          </Blurb>
          <Blurb>
            <HeartIcon />
            <p>
              In supported accommodation, who had a range of complex and high
              support needs
            </p>
          </Blurb>
          <Blurb>
            <HeartIcon />
            <p>
              To help them move past their traumas, disengage from anti- social
              and destructive behaviours and gain independence and stability in
              their life.
            </p>
          </Blurb>
        </BlurbWrapper>
      </SectionWrapper>
      <SectionWrapper>
        <H2Wrapper>
          <H2>I’VE MANAGED...</H2>
          <UnderlineStyle />
        </H2Wrapper>
        <BlurbWrapper>
          <Blurb>
            <HeartIcon />
            <p>
              Floating Support Services for the homeless in one of the most
              deprived areas in London.
            </p>
          </Blurb>
          <Blurb>
            <HeartIcon />
            <p>Older people supported accommodation services</p>
          </Blurb>
          <Blurb>
            <HeartIcon />
            <p>
              Mother and baby units, for vulnerable mothers, many who had
              experienced domestic violence and abuse.
            </p>
          </Blurb>
          <Blurb>
            <HeartIcon />
            <p>...and set up young people's supported accommodation.</p>
          </Blurb>
        </BlurbWrapper>
      </SectionWrapper>
    </Wrapper>
  );
};

export default MyExperience;
