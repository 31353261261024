import SplitSection from "components/shared/SplitSection";
import { BREAKPOINTS, COLORS } from "constants/style";
import styled from "styled-components";
import HeartIcon from "images/icons/heart.svg";

const SplitSectionWrapper = styled.div`
  background-color: ${COLORS.lightGrey};
  max-width: 1100px;
  width: 80%;
  margin: 0 auto 100px;
  padding: 80px;

  ul {
    text-align: left;
    padding-inline-start: 14px;
  }

  li {
    margin: 30px 0;
    font-size: 1.25rem;
    line-height: 1.75rem;
    list-style: none;
    padding: 0px 0 0 35px;
    list-style: none;
    background-image: url(${HeartIcon});
    background-repeat: no-repeat;
    background-position: left 4px;
    background-size: 20px;
  }

  @media (max-width: ${BREAKPOINTS.mobileMax}) {
    padding: 70px 25px;

    ul {
      padding-inline-start: 0;
    }
  }

  @media (max-width: ${BREAKPOINTS.mobileSmallMax}) {
    padding: 70px 15px;
    width: 90%;
  }
`;

const LeftContent = () => {
  return (
    <ul>
      <li>Feeling stuck or lost</li>
      <li>Wanting to find your purpose and reach your potential</li>
      <li>
        Working through and letting go of emotions as a result of major life
        events & finding a way forward
      </li>
      <li>Speaking your truth</li>
      <li>Building your confidence</li>
      <li>Burn out</li>
      <li>Wellbeing, anxiety</li>
      <li>Working through self sabotage, destructive behaviours</li>
    </ul>
  );
};

const RightContent = () => {
  return (
    <ul>
      <li>Changing your mindset and perspective in order to be happier</li>
      <li>Letting go of limiting beliefs</li>
      <li>Loneliness</li>
      <li>Connecting with your true self</li>
      <li>Deep rooted fears</li>
      <li>Imposter syndrome</li>
      <li>Finding your path</li>
      <li>Finding wholeness</li>
      <li>Self love, self worth</li>
    </ul>
  );
};

const CoachingHelpsWith = () => {
  return (
    <SplitSectionWrapper>
      <SplitSection
        title="What can coaching help with?"
        split="half"
        left={<LeftContent />}
        right={<RightContent />}
        bgColor="transparent"
      />
    </SplitSectionWrapper>
  );
};

export default CoachingHelpsWith;
