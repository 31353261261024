import { BREAKPOINTS, COLORS } from "constants/style";
import styled from "styled-components";

const LineStyle = styled.hr<{ padded?: boolean }>`
  background: ${COLORS.black};
  color: ${COLORS.black};
  border-color: ${COLORS.black};
  width: ${({ padded }) => (padded ? "85%" : "100%")};
  // margin: 0 3rem;
  border-style: solid;
  border-width: 0.5px;

  // @media (max-width: ${BREAKPOINTS.mobileMax}) {
  //   margin: 0 1.5rem;
  // }
`;

const HorizontalRule = ({ padded }: { padded?: boolean }) => {
  return <LineStyle padded={padded} />;
};

export default HorizontalRule;
