import HeaderNavLinks from "components/Header/HeaderNavLinks";
import { COLORS } from "constants/style";
import styled from "styled-components";

const HeaderWrapper = styled.ul`
  list-style-type: none;
  text-decoration: none;
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
    text-transform: uppercase;
    margin: 0 1.25rem;
    font-size: 1.25rem;
    line-height: 20px;
    font-weight: 500;
    letter-spacing: 1px;
  }
`;

const NavBar = () => {
  return (
    <HeaderWrapper>
      <HeaderNavLinks linkColor={COLORS.black} showUnderline />
    </HeaderWrapper>
  );
};

export default NavBar;
