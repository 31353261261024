import * as DOMPurify from 'dompurify';
import { marked } from "marked";
import parse, { Element, domToReact } from "html-react-parser";
import { H2, H3, TextBox } from "./Typography";
import styled from "styled-components";

const MarkdownWrapper = styled.div`
  ul {
    list-style-position: inside;
  }
`;

/**
 * Markdown Renderer
 * This is needed for description.
 */
const markdownRenderer = {
  rendererReady: false,
  prepareMarkdownRenderer() {
    const renderer = new marked.Renderer();
    renderer.link = function (html: string, title: string, text: string) {
      return marked.Renderer.prototype.link.apply(this, [html, title, text]);
    };
    marked.setOptions({ renderer });
    this.rendererReady = true;
  },
};

const openChildLinksInNewTab = (child: Element) => {
  const { name: childName, attribs } = child;
  if (childName === "a") {
    const newAttribs = {
      ...(attribs || {}),
      target: "_blank",
      rel: "noopener noreferrer",
    };
    return {
      ...child,
      attribs: newAttribs,
    };
  }
  return child;
};

export const parseMarkdown = (text: string) => {
  if (!text) {
    return;
  }

  if (!markdownRenderer.rendererReady) {
    markdownRenderer.prepareMarkdownRenderer();
  }

  const html = marked(
    // Remove any tabs, that will create code blocks
    DOMPurify.sanitize(text.replace("\t", " ")),
    { gfm: true }
  );

  if (!html) {
    return;
  }

  const contents = parse(html, {
    replace: (node: any) => {
      const { children, name } = node;
      switch (name) {
        case "h1":
        case "h2":
          return children ? (
            <H2>
              {domToReact(children.map(openChildLinksInNewTab))}
            </H2>
          ) : null;
        case "h3":
        case "h4":
        case "h5":
        case "h6":
          return children ? (
            <H3>
              {domToReact(children.map(openChildLinksInNewTab))}
            </H3>
          ) : null;
        case "p":
          return children ? (
            <TextBox>{domToReact(children.map(openChildLinksInNewTab))}</TextBox>
          ) : null;
        case "li":
          return children ? (
            <li>{domToReact(children.map(openChildLinksInNewTab))}</li>
          ) : null;
        case "a":
          return openChildLinksInNewTab(node);
        default:
          return;
      }
    },
  });

  return (
    <MarkdownWrapper>
      <div>{contents}</div>
    </MarkdownWrapper>
  );
};
