import { Bold, H3, TextBox } from "helpers/Typography";
import { TechniquesCTA, Wrapper } from "../Shared/Shared";

const Copy = () => {
  return (
    <Wrapper>
      <H3>What is positive psychology?</H3>
      <TextBox>
        Positive psychology has been referred to as the science of happiness and
        human potential , and so particularly relevant in coaching. Clients who
        seek coaching , ultimately want to find happiness.
      </TextBox>
      <TextBox>
        Coaching Psychology is for enhancing well-being and performance in
        personal life and work domains underpinned by models of coaching
        grounded in established adult learning or psychological approaches.”
        (Palmer & Whybrow, 2005, p.7, adapted from Grant & Palmer, 2002)
      </TextBox>
      <H3>A positive psychology approach in coaching, can help you to:</H3>
      <TextBox>
        <ul>
          <li>Increase your wellbeing</li>
          <li>
            Enhance and apply your strengths in your work or personal life
          </li>
          <li>Improve performance </li>
          <li>Achieve your goals</li>
          <li>Move from a fixed to a growth mindset </li>
          <li>Shift your perspective</li>
        </ul>
      </TextBox>
      <TextBox>
        Through this approach, the coaching experience will be enhanced, and you
        can begin to flourish in life focusing on strengths and building
        resilience.This approach can be particularly helpful to clients who have
        experienced relationship troubles, trauma , or other existential crises.
      </TextBox>
      <H3>Using the approach together we can:</H3>
      <TextBox>
        <ul>
          <li>Focus on your strengths</li>
          <li>
            Help you move towards a growth mindset - which means anything can be
            possible for you
          </li>
          <li>
            Examine: strengths, virtues, wisdom, beliefs, values, emotional
            intelligence, positive coping, humanity, creativity. Positive
            emotions, positive aging and more.
          </li>
          <li>
            Explore painful experiences you may have had, examining
            uncomfortable thoughts, feelings, behaviors, moving towards working
            on letting go and moving forward in life.
          </li>
        </ul>
      </TextBox>
      <TextBox>
        The fact is, people can <Bold>CHANGE</Bold>.
      </TextBox>
      <TextBox>
        You can <Bold>CHANGE</Bold>. You can begin to flourish instead of
        survive. You can live more fully.
      </TextBox>
      <TextBox>
        You can begin to make <Bold>CHANGES</Bold> in your life and move from
        where you are to where you want to be.
      </TextBox>
      <TechniquesCTA />
    </Wrapper>
  );
};

export default Copy;
