import HeroImageShort from "components/Header/HeroImageShort";
import Header from "images/transformative-coaching-header.jpeg";
import Copy from "./Copy";

const TransformativeCoaching = () => {
  return (
    <>
      <HeroImageShort
        imgDesktop={{ img: Header, position: "center" }}
        imgWide={{ img: Header, position: "center" }}
        imgMobile={{ img: Header, position: "center" }}
      >
        Transformative Coaching
      </HeroImageShort>
      <Copy />
    </>
  );
};

export default TransformativeCoaching;
