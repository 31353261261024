export type Testimonial = {
  name: string;
  quote: string;
  full: string;
};

export const TESTIMONIALS: Testimonial[] = [
  {
    name: "Monica",
    quote:
      "<p>Before I began working with Sue I was really struggling with making decisions, personal goals, and self-belief. I had been in counseling in the past and found it difficult to open up and talk about why I was struggling. What is helpful with Sue’s service is Sue’s down-to-earth tailored approach. Sue works with you and helps you in a practical way to approach aspects of your life you are finding difficult, as well as looking inward to better understand why this may be happening. I would highly recommend Sue’s transformative coaching services.</p>",
    full: "<p>Before I began working with Sue I was really struggling with making decisions, personal goals, and self-belief. I had been in counseling in the past and found it difficult to open up and talk about why I was struggling. What is helpful with Sue’s service is Sue’s down-to-earth tailored approach. Sue works with you and helps you in a practical way to approach aspects of your life you are finding difficult, as well as looking inward to better understand why this may be happening. I would highly recommend Sue’s transformative coaching services.</p>",
  },
  {
    name: "Jackie",
    quote:
      "<p>Having the opportunity of meeting Sue to have as a coach came at the right time. At the beginning my mind was feeling very scrambled, after a number of coaching sessions with Sue a lot of my intrusive thinking became to unravel and become less paranoid. Sue was very good about us exploring how I can change my negative thoughts especially work situations into a more balanced, positive approach. Sue kept me grounded and I got a lot from doing the visualisation exercise.</p><p>Thank you so much Sue, I really appreciate our coaching sessions.</p>",
    full: "<p>Having the opportunity of meeting Sue to have as a coach came at the right time. At the beginning my mind was feeling very scrambled, after a number of coaching sessions with Sue a lot of my intrusive thinking became to unravel and become less paranoid. Sue was very good about us exploring how I can change my negative thoughts especially work situations into a more balanced, positive approach. Sue kept me grounded and I got a lot from doing the visualisation exercise.</p><p>Thank you so much Sue, I really appreciate our coaching sessions.</p>",
  },
  {
    name: "Constantine",
    quote:
      "<p>From my very first session with Sue I was blown away! I had the most healing hour I had experienced for a long time. Sue is calm and kind and her questions bring about some fascinating insights. I feel unequivocally safe and held in her container. The insights I gain from each session are deeply transformational and nothing short of magic. Being coached by Sue has induced incredible changes in my life by prompting me to acknowledge and take action on certain areas. The sessions are deep but also uplifting. Sue will take you straight to the heart of the matter. Sue has a potent gift for life coaching. I would recommend Sue to anyone.</p>",
    full: "<p>From my very first session with Sue I was blown away! I had the most healing hour I had experienced for a long time. Sue is calm and kind and her questions bring about some fascinating insights. I feel unequivocally safe and held in her container. The insights I gain from each session are deeply transformational and nothing short of magic. Being coached by Sue has induced incredible changes in my life by prompting me to acknowledge and take action on certain areas. The sessions are deep but also uplifting. Sue will take you straight to the heart of the matter. Sue has a potent gift for life coaching. I would recommend Sue to anyone.</p>",
  },
  {
    name: "Joanne",
    quote:
      "<p>Working with Sue has been incredible. She has helped me identify my values, address self limiting beliefs, and set goals for the future, and make action plans that I have worked on, to achieve them.</p><p>Dedicating time to focus on myself and having Sue as my guide, to break things down and help me find my way has been worthwhile and deeply transformational. Her positivity makes the whole process less painful. Sue makes everything seem less daunting and more achievable.</p>",
    full: "<p>Working with Sue has been incredible. She has helped me identify my values, address self limiting beliefs, and set goals for the future, and make action plans that I have worked on, to achieve them.</p><p>Dedicating time to focus on myself and having Sue as my guide, to break things down and help me find my way has been worthwhile and deeply transformational. Her positivity makes the whole process less painful. Sue makes everything seem less daunting and more achievable.</p>",
  },
  {
    name: "Nyree",
    quote:
      "<p>I feel so privileged and grateful to have met Sue, and for the time and energy she invested into our coaching sessions. In the relatively short space of time I was lucky to spend with Sue, it was abundantly clear to me that she has a truly special gift. A beautiful soul - deeply intuitive, wise and patient, Sue is not only a vibrant ray of light and a total joy to talk to, but also quietly strong and capable of handling the most fragile, dark parts of a person with care, gentleness and without judgement...</p>",
    full: "<p>I feel so privileged and grateful to have met Sue, and for the time and energy she invested into our coaching sessions. In the relatively short space of time I was lucky to spend with Sue, it was abundantly clear to me that she has a truly special gift. A beautiful soul - deeply intuitive, wise and patient, Sue is not only a vibrant ray of light and a total joy to talk to, but also quietly strong and capable of handling the most fragile, dark parts of a person with care, gentleness and without judgement.</p><p>My sessions with Sue were open, honest, and deeply insightful, and I always (without fail!) came away from them feeling lighter, clearer and more positive than I went into them. Some of the things I had been struggling with and wanted to unpack with Sue were deeply personal: including limiting beliefs and trauma from relationships - things I had found difficult to talk about with even my closest friends. Straight away from our very first call, Sue put me at ease and I absolutely knew I was in safe, capable and kind hands.</p><p>Her insight and wisdom alongside her ability to create a safe space for me to open up and express myself freely, has helped me to untangle years of complex issues and negative patterns in such a short space of time. I have absolutely no doubt that my coaching sessions with Sue have had, and will continue to have, a positive impact on my life going forwards. I would absolutely love to work with her again in the future, and to anyone considering coaching sessions - I really can't recommend Sue enough. I feel incredibly lucky to have been part of her coaching journey so far, and I'm so excited to see what lies ahead for Sue as she continues to follow what I'm sure is her soul's calling.</p>",
  },
  {
    name: "Lesly",
    quote:
      "<p>I feel very lucky to have had the coaching sessions with Sue.  She has helped me realise the issues that were holding me back, and how to deal with these going forward.  I was very nervous at the beginning of the first coaching session but Sue made me feel at ease and relaxed during the sessions.</p><p>Sue is a very positive person with a positive outlook and knows how to bring this to other people.  This positive person rubs off in the coaching session.  I am very grateful for the time during the coaching session…</p>",
    full: "<p>I feel very lucky to have had the coaching sessions with Sue.  She has helped me realise the issues that were holding me back, and how to deal with these going forward.  I was very nervous at the beginning of the first coaching session but Sue made me feel at ease and relaxed during the sessions.</p><p>Sue is a very positive person with a positive outlook and knows how to bring this to other people.  This positive person rubs off in the coaching session.  I am very grateful for the time during the coaching session.</p><p>Sue is a very intuitive person and approaches the coaching sessions in a very insightful way.</p><p>I would wholeheartedly recommend Sue.</p>",
  },
  {
    name: "Smita",
    quote: `<p>I have always given my strength, energy, time, and yes, would go as far as my soul, to others. "Me time" just didn’t exist for me, and boy did I pay a big price with my mental and physical health.</p><p>What Sue has been able to do with me, that others have not, is helping me to understand self love, self worth, self compassion. These 3 things weren't on my radar at all. Sue has been helping me to find my identity, tapping in to my deep feelings as a person of worth and focusing on what my core purpose is in life, and both present and future aspirations...</p>`,
    full: `<p>I have always given my strength, energy, time, and yes, would go as far as my soul, to others. "Me time" just didn’t exist for me, and boy did I pay a big price with my mental and physical health.</p><p>What Sue has been able to do with me, that others have not, is helping me to understand self love, self worth, self compassion. These 3 things weren't on my radar at all. Sue has been helping me to find my identity, tapping in to my deep feelings as a person of worth and focusing on what my core purpose is in life, and both present and future aspirations.</p><p>She has helped me focus on getting back my autonomy, to live for me and only me. It sounds selfish but it isn’t. Through doing this it has helped me focus on my positive energies in life, invigorating my mental & physical health. Learning the boundaries and still giving to others, but importantly, giving to myself as well.</p><p>I would highly recommend having these sessions with Sue. She’s open, honest, trusting and, more importantly, she gets it. It’s work in progress but I feel high after our sessions, she’s made be believe I am worth it!</p>`,
  },
];
