import Button from "components/shared/Button";
import { BREAKPOINTS, COLORS } from "constants/style";
import { Bold, CursiveH2, TextBox } from "helpers/Typography";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Wrapper = styled.div`
  background-color: ${COLORS.lightGreen};
  margin: 0 auto 100px;
  padding: 70px;
  width: 80%;
  max-width: 800px;

  @media (max-width: ${BREAKPOINTS.mobileMax}) {
    width: 90%;
    padding: 50px 30px;
  }

  @media (max-width: ${BREAKPOINTS.mobileSmallMax}) {
    width: 90%;
    padding: 30px 20px;
  }
`;

const PersonalExperience = () => {
  return (
    <Wrapper>
      <CursiveH2>My personal experience of Tapping</CursiveH2>
      <TextBox>
        The first time I discovered Tapping was during a coaching session a
        number of years ago. At the time, I was experiencing a lot of self
        doubt, and my inner critic was holding me back. This was showing up for
        me in my work and personal life - not feeling good enough. Always
        questioning myself and my abilities.
      </TextBox>
      <TextBox>This was holding me back and dimming my light.</TextBox>
      <TextBox>
        Through delving into my subconscious during the Tapping, I learned that
        I had developed a limiting belief , as a trauma response. I believed I
        wasn't good enough. A series of traumatic life events had led me to this
        belief. Tapping helped me to release my emotions. I allowed the tears to
        fall freely , and allow the emotions of previous trauma to be released.
      </TextBox>
      <TextBox>
        With the support of my coach, I could flip that limiting belief around
        to a more positive, empowering and truthful one -{" "}
        <Bold>I AM GOOD ENOUGH</Bold>.
      </TextBox>
      <TextBox>What followed was:</TextBox>
      <TextBox>
        <ul>
          <li>
            A huge sense of relief, like a weight had been lifted off my
            shoulders
          </li>
          <li>Resolution from a traumatic memorye</li>
          <li>A sense of calmness and peace</li>
          <li>Emotional freedom</li>
          <li>Inner fulfillment with the knowing that I am good enough</li>
          <li>Confidence and forward action in my work and personal life</li>
        </ul>
      </TextBox>
      <TextBox>How can you try this?</TextBox>
      <TextBox>
        Book a complementary clarity call with me and we can talk about how EFT
        can help you.
      </TextBox>
      <Link to="/contact">
        <Button text="Book now" />
      </Link>
    </Wrapper>
  );
};

export default PersonalExperience;
