import SplitSection from "components/shared/SplitSection";
import { BREAKPOINTS } from "constants/style";
import styled from "styled-components";
import Copy from "./Copy";
import Form from "./Form";

const Wrapper = styled.div`
  margin: 50px auto 100px;
`;

const ContentWrapper = styled.div`
  margin: 40px auto 0;
  max-width: 1000px;
  width: 90%;

  @media (max-width: ${BREAKPOINTS.twoColumnBreakpoint}) {
    margin: 20px auto 0;
  }

  @media (max-width: ${BREAKPOINTS.mobileMax}) {
    margin: 0 auto;
  }
`;

export const ContactH1 = styled.h1`
  font-family: "Amalfi Coast";
  font-size: 3.25rem;
  line-height: 7.25rem;
  margin: 0;

  @media (max-width: ${BREAKPOINTS.tabletMax}) {
    font-size: 3rem;
    line-height: 6rem;
  }

  @media (max-width: ${BREAKPOINTS.mobileMax}) {
    font-size: 2.5rem;
    line-height: 5rem;
  }
`;

const Contact = () => {
  return (
    <Wrapper>
      <ContactH1>Contact Me</ContactH1>
      <ContentWrapper>
      <SplitSection
        gap={{ column: 10, row: 40 }}
        split="half"
        left={<Copy />}
        right={<Form />}
      />
      </ContentWrapper>
    </Wrapper>
  );
};

export default Contact;
