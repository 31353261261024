import { BREAKPOINTS } from "constants/style";
import { useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Button from "../shared/Button";
import HamburgerMenu from "./HamburgerMenu";
import Logo from "./Logo";
import MobileNav from "./MobileNav";
import NavBar from "./NavBar";

const HeaderWrapper = styled.div`
  max-width: 1512px;
  margin: 0 auto;
  width: 100%;
  height: auto;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
`;

const Header = () => {
  const [open, setOpen] = useState(false);
  const node = useRef<HTMLDivElement | null>(null);
  const isTabletOrMobile = useMediaQuery({
    query: `(max-width: ${BREAKPOINTS.tabletMax})`,
  });
  return (
    <HeaderWrapper>
      <Logo />
      {isTabletOrMobile ? (
        <div ref={node}>
          <HamburgerMenu open={open} setOpen={setOpen} />
          <MobileNav open={open} setOpen={setOpen} />
        </div>
      ) : (
        <>
          <NavBar />
          <Link to="contact"><Button text="Contact" /></Link>
        </>
      )}
    </HeaderWrapper>
  );
};

export default Header;
